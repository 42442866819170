import React from "react";
import styles from "./PrivacyPolicy.module.css";
import BackBtn from "../backBtn/BackBtn";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <>
      <BackBtn bgColor={"red"}/>
      <div className={`${styles.container} ${theme === 'dark' ? 'bg-black text-white' : 'bg-white'}`}>
        <h1 className={styles.title}>BugTrack Privacy Policy</h1>
        <div className={styles.content}>
          <h2>Data Privacy</h2>
          <p>
            BugTrack collects user data, including personal information such as
            name and email, as well as activity data, including screenshots and
            time logs. BugTrack is committed to safeguarding your privacy, and
            data is only shared with authorized parties in line with the
            functionality of the platform.
          </p>

          <h2>Information We Collect</h2>
          <p>
            We collect data through your interactions with the BugTrack
            platform, such as login activity, time tracked, and productivity
            statistics. Additionally, screenshots are captured by the desktop
            application for monitoring and analysis. All this data is securely
            stored in our database.
          </p>

          <h2>How We Use Your Data</h2>
          <p>
            The data we collect is used to generate productivity reports, track
            time spent on tasks, and provide insights to users and admins about
            work patterns. Data is also used to improve our services and provide
            a seamless experience.
          </p>

          <h2>Data Sharing</h2>
          <p>
            BugTrack does not share user data with third-party services unless
            required for legal or operational purposes. Reports generated on
            BugTrack, including time logs and screenshots, are only accessible
            to the user or admin they are intended for.
          </p>

          <h2>User Control Over Data</h2>
          <p>
            Users can access, update, or delete their personal information and
            activity logs through their account settings. Should you wish to
            delete your account, all associated data will be permanently removed
            from our system.
          </p>

          <h2>Security of Data</h2>
          <p>
            We employ industry-standard security measures to protect user data.
            While we strive to ensure complete protection, no system is entirely
            foolproof, and we encourage users to take personal measures to
            protect their data as well.
          </p>

          <h2>Changes to Privacy Policy</h2>
          <p>
            BugTrack reserves the right to update this privacy policy. Users
            will be notified of any changes via email or through the platform.
            Continued use of BugTrack following updates constitutes acceptance
            of the new terms.
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
