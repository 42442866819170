import React, { useState, useEffect } from "react";
import styles from "./personalInformation.module.css";
import { useDispatch, useSelector } from "react-redux";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchApi } from "../../../../utlis/axios";
import { toggleAuth } from "../../../../Redux/Slices/auth-slice";
import ParticlesBackground from "../../../home/ParticalsBackground";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const PersonalInformation = () => {
  const { theme } = useSelector((state) => state.theme);
  const { isLogin, userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch();
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    role: "",
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);

  useEffect(() => {
    if (userInfo) {
      setFormData((prevData) => ({
        ...prevData,
        first_name: userInfo?.user?.first_name || "",
        last_name: userInfo?.user?.last_name || "",
        email: userInfo?.user?.email || "",
        role: userInfo?.user?.role?.name || "",
      }));
    }
  }, [userInfo]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "newPassword") {
      calculatePasswordStrength(e.target.value);
    }
  };
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword.length < 8) {
      toast.dismiss();
      toast.error("Password must be at least 8 characters!");
      return;
    }
    try {
      const response = await fetchApi.patch(
        `users/${userInfo?.user?.id}/`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (response.status === 200) {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          role: "",
          currentPassword: "",
          newPassword: "",
          confirmPassword: "",
        });
        toast.dismiss();
        toast.success("Information updated successfully!");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      toast.dismiss();
      toast.error(
        "Error updating user information: " +
          (error.response?.data?.message || "Something went wrong.")
      );
    }
  };

  return (
    <>
      <style>{`
        .input {
          background-color: rgba(255, 255, 255, 0.1); 
    backdrop-filter: blur(8px);
          color: ${theme === "dark" ? "#ffffff" : "#000000"};
          width: 100%;
          border: 1px solid #828282;
          height: calc(2.25rem + 2px);
          font-weight: 400;
          font-size: .875rem;
          padding: .625rem .6875rem;
          border-radius: 10px;
        }
      `}</style>
       <div
            className={`${styles.main_Heading} ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Personal Information
          </div>
          <hr className="w-full" />
      <form
        onSubmit={handleSubmit}
        className={`${styles.container} ${
          theme === "dark" ? " text-white" : "text-black"
        }`}
      >
        <div className="md:flex justify-between">
          <div className={styles.formGroup}>
            <label className={styles.label}>
              First Name:
              <input
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                className="input"
              />
            </label>
          </div>
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Last Name:
              <input
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                className="input"
              />
            </label>
          </div>
        </div>
        <div className="md:flex justify-between">
          <div className={styles.formGroup}>
            <label className={styles.label}>
              Email:
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="input"
              />
            </label>
          </div>
          <div className={`${styles.formGroup}`}>
            <label className={`w-full ${styles.label}`}>
              Current Password:
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="currentPassword"
                  value={formData.currentPassword}
                  onChange={handleChange}
                  className="input"
                />
                <div
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </label>
          </div>
          {/* <div className={styles.formGroup}>
            <label className={styles.label}>
              Role:
              <input
                type="text"
                name="role"
                value={formData.role}
                onChange={handleChange}
                className="input"
              />
            </label>
          </div> */}
        </div>
        <div className="md:flex justify-between">
          <div className={`flex-col ${styles.formGroup}`}>
            <label className={`w-full ${styles.label}`}>
              New Password:
              <div className="relative">
                <input
                  type={showNewPassword ? "text" : "password"}
                  name="newPassword"
                  value={formData.newPassword}
                  onChange={handleChange}
                  className="input"
                />
                <div
                  className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                  onClick={() => setNewShowPassword(!showNewPassword)}
                >
                  {showNewPassword ? <FaEye /> : <FaEyeSlash />}
                </div>
              </div>
            </label>
            <div className="w-full h-2 mb-2 rounded-full overflow-hidden">
              <div
                className={`h-full rounded-full transition-all duration-300 ${
                  passwordStrength < 2
                    ? "bg-red-500"
                    : passwordStrength < 4
                    ? "bg-orange-500"
                    : "bg-green-500"
                }`}
                style={{ width: `${(passwordStrength / 5) * 100}%` }}
              ></div>
            </div>
          </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>
            Confirm New Password:
            <input
              type={showNewPassword ? "text" : "password"}
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              className="input"
            />
          <div className="w-full h-2 mb-2 rounded-full overflow-hidden"></div>
          </label>

        </div>
        </div>


        <div className={styles.formGroup}>
          <button type="submit" className="heroButton">
            Save Changes
          </button>
        </div>
      </form>
      <ToastContainer />
    </>
  );
};

export default PersonalInformation;
