import React from "react";
import AnimationLottie from "../../../lottie/animation-lottie";
import workingAnimation from "../../../lottie/workingAnimation.json";
import styles from "./styles.module.css"; 
import { Link } from "react-router-dom";
  
const HeroSection = () => {
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.heroText}>
          <h2 className="main-heading">Maximize Your Efficiency</h2>
          <p>
            Track time, manage tasks, and boost productivity with our powerful
            desktop app.
          </p>
          <Link to="/your-domain-name/login" className="heroButton">
            Start Logging Time
          </Link>
        </div>
        <div className={styles.heroAnimation}>
          <AnimationLottie animationPath={workingAnimation} />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
