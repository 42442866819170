import React, { useEffect, useRef, useState } from "react";
import {
  FaArrowLeft,
  FaArrowRight,
  FaMouse,
  FaRegLightbulb,
  FaKeyboard,
} from "react-icons/fa";
import { FiActivity } from "react-icons/fi";
import { IoIosArrowDown,IoMdArrowDropdown } from "react-icons/io";
import styles from "./activity.module.css";
import OfflineActivityForm from "./OfflineActivityForm";
import FilterModal from "../filterModal/FilterModal";
import PreviewModal from "../previewModal/PreviewModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { togglesummaryData } from "../../../Redux/Slices/summaryData-slice";

const Activity = () => {
  const { theme } = useSelector((state) => state.theme);
  const { summaryDate } = useSelector((state) => state.summaryData);
  const { summaryUserId } = useSelector((state) => state.summaryData);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [showOfflineActivity, setShowOfflineActivity] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [userSummary, setUserSummary] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [dataByDate, setDataByDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [isOpen, setIsOpen] = useState(false);
  const [idFromSummary, setIdFromSummary] = useState();
  const dispatch = useDispatch();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const dropdownRef = useRef(null);
  const [randomScreenShots, setRandomScreenShots] = useState(false);
  const [imageActivityRows, setImageActivityRows] = useState([]);
  const [dataToRender, setDataToRender] = useState([]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpenDropdownId(null);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdownId((prevId) => (prevId === dropdownId ? null : dropdownId));
  };

  const getUsers = async () => {
    setIsloading(true);
    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        if (userInfo?.user?.role?.name === "Time Reporter") {
          setUserId(userInfo?.user?.id);
        } else {
          const result = response?.data?.results?.filter(
            (item) =>
              item.role.name === "Time Reporter" &&
              item.domain === userInfo.user.domain
          );
          if (result.length === 0) {
            setUserId(userInfo?.user?.id);
          } else {
            setUserId(result?.[0]?.id);
          }
          setUsers(result);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      console.log(error);
      setIsloading(false);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getSummaryData = async () => {
    if (summaryDate !== null) {
      setDataByDate(summaryDate);
      setIdFromSummary(summaryUserId);
    }
    if (userId) {
      setIsloading(true);
      const dataById = summaryUserId || userId;
      try {
        const response = await fetchApi.get(
          `user-summaries/?user=${dataById}&date=${dataByDate}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );
        if (response.status === 200) {
          setIsloading(false);
          setUserSummary(response.data.results[0]);
          dispatch(
            togglesummaryData({ summaryDate: null, summaryUserId: null })
          );
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          toast.dismiss();
          toast.error("Your session is expired");
          return;
        }
        setIsloading(false);
        console.log("Error while call user-summaries API: ", error);
        toast.dismiss();
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getSummaryData();
  }, [userId, dataByDate]);

  const joiningDate = new Date(userInfo?.user?.created_at);
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];

    // Add previous month's days in correct order
    const startDay = firstDayOfMonth.getDay(); // Day of the week (0 for Sunday, 1 for Monday, etc.)
    for (let i = startDay - 1; i >= 0; i--) {
      daysInMonth.push({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
      });
    }

    // Add current month's days
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        day: i,
        currentMonth: true,
      });
    }

    // Add next month's days
    const remainingDays = 35 - daysInMonth.length; // Adjust to ensure a 5-week grid
    for (let i = 1; i <= remainingDays; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysInMonth);
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };
  let getRowsWithAverages;
  if (randomScreenShots) {
    getRowsWithAverages = (activities = []) => {
      const defaultRowLength = 3;

      if (!Array.isArray(activities)) {
        activities = [];
      }

      const convertTo24HourFormat = (hour, period) => {
        let hours = parseInt(hour, 10);
        if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
        if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
        return hours;
      };

      const parseHourSlot = (hourSlot) => {
        if (hourSlot.includes(":")) {
          // Format: "11:10am - 11:20am"
          const [startTime, endTime] = hourSlot.split(" - ");
          const [startHourMin, startPeriod] = startTime
            .trim()
            .split(/(?=[APap][Mm])/);
          const [endHourMin, endPeriod] = endTime
            .trim()
            .split(/(?=[APap][Mm])/);

          const start = convertTo24HourFormat(
            startHourMin.split(":")[0],
            startPeriod
          );
          const end = convertTo24HourFormat(
            endHourMin.split(":")[0],
            endPeriod
          );

          return { start, end, rawSlot: hourSlot };
        } else {
          // Format: "11am - 12pm"
          const [startHour, startPeriod, , endHour, endPeriod] =
            hourSlot.split(" ");
          const start = convertTo24HourFormat(startHour, startPeriod);
          const end = convertTo24HourFormat(endHour, endPeriod);

          return { start, end, rawSlot: hourSlot };
        }
      };

      const groupedByHourSlot = activities.reduce((acc, activity) => {
        const { hour_slot, kpp, mpp, minute_to_add } = activity;

        const { rawSlot } = parseHourSlot(hour_slot);

        const minutesToAdd = isNaN(minute_to_add)
          ? 0
          : parseInt(minute_to_add, 10);

        if (!acc[rawSlot]) {
          acc[rawSlot] = {
            hour_slot: rawSlot,
            kppSum: isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp),
            mppSum: isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp),
            totalMinutes: minutesToAdd,
            count: 1,
          };
        } else {
          acc[rawSlot].kppSum += isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp);
          acc[rawSlot].mppSum += isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp);
          acc[rawSlot].totalMinutes += minutesToAdd;
          acc[rawSlot].count += 1;
        }

        return acc;
      }, {});

      let result = Object.values(groupedByHourSlot).map((group, index) => ({
        hour_slot: group.hour_slot,
        avgKpp: group.count > 0 ? Math.round(group.kppSum / group.count) : 0,
        avgMpp: group.count > 0 ? Math.round(group.mppSum / group.count) : 0,
        totalMinutes: group.totalMinutes,
        rowNumber: index + 1,
      }));

      const sampleData = [
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 1,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 2,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 3,
        },
      ];

      if (result.length < defaultRowLength) {
        const neededRows = defaultRowLength - result.length;
        result = [...result, ...sampleData.slice(0, neededRows)];
      }
      
      return result;
    };
  } else {
    getRowsWithAverages = (imageActivity = []) => {
      const defaultRowLength = 3;

      if (!Array.isArray(imageActivity)) {
        imageActivity = [];
      }

      const convertTo24HourFormat = (time, period) => {
        if (randomScreenShots) {
          let hours = parseInt(time, 10);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return hours;
        } else {
          let [hours, minutes] = time.split(":").map(Number);
          if (period?.toLowerCase() === "pm" && hours !== 12) hours += 12;
          if (period?.toLowerCase() === "am" && hours === 12) hours = 0;
          return { hours, minutes: minutes || 0 };
        }
      };

      const groupedByHourSlot = imageActivity.reduce((acc, activity) => {
        const { hour_slot, kpp, mpp, minute_to_add } = activity;

        // Parse hour_slot string format "11:00 AM - 11:10 AM"
        const [startTime, endTime] = hour_slot.split(" - ");
        const [startTimeStr, startPeriod] = startTime.trim().split(" ");
        const start = convertTo24HourFormat(startTimeStr, startPeriod);

        // Generate hour slot range like "11:00 AM - 12:00 PM"
        const startHourFormatted =
          start.hours % 12 === 0 ? 12 : start.hours % 12;
          console.log(start);
          
        const endHourFormatted =
          (start.hours + 1) % 12 === 0 ? 12 : (start.hours + 1) % 12;
        const amPmStart = start.hours >= 12 ? "PM" : "AM";
        const amPmEnd = start.hours + 1 >= 12 ? "PM" : "AM";

        const hourString = `${startHourFormatted}:00 ${amPmStart} - ${endHourFormatted}:00 ${amPmEnd}`;

        // Ensure minute_to_add is treated as an integer
        const minutesToAdd = isNaN(minute_to_add)
          ? 0
          : parseInt(minute_to_add, 10);

        // Group by hour slot
        if (!acc[hourString]) {
          acc[hourString] = {
            hour_slot: hourString,
            kppSum: isNaN(parseFloat(kpp)) ? 0 : parseFloat(kpp),
            mppSum: isNaN(parseFloat(mpp)) ? 0 : parseFloat(mpp),
            totalMinutes: minutesToAdd,
            count: 1,
          };
        } else {
          acc[hourString].kppSum += isNaN(parseFloat(kpp))
            ? 0
            : parseFloat(kpp);
          acc[hourString].mppSum += isNaN(parseFloat(mpp))
            ? 0
            : parseFloat(mpp);
          acc[hourString].totalMinutes += minutesToAdd;
          acc[hourString].count += 1;
        }

        return acc;
      }, {});

      // Map the grouped data to the final result format
      let result = Object.values(groupedByHourSlot).map((group, index) => ({
        hour_slot: group.hour_slot,
        avgKpp: group.count > 0 ? Math.round(group.kppSum / group.count) : 0,
        avgMpp: group.count > 0 ? Math.round(group.mppSum / group.count) : 0,
        totalMinutes: group.totalMinutes,
        rowNumber: index + 1,
      }));

      // Sample data rows if not enough data is present
      const sampleData = [
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 1,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 2,
        },
        {
          hour_slot: "00",
          avgKpp: 0,
          avgMpp: 0,
          totalMinutes: 0,
          rowNumber: result.length + 3,
        },
      ];

      // Ensure there are at least 3 rows
      if (result.length < defaultRowLength) {
        const neededRows = defaultRowLength - result.length;
        result = [...result, ...sampleData.slice(0, neededRows)];
      }
      console.log('451',result.map((d)=>console.log(d)
      ));
      
      return result;
    };
  }

  useEffect(() => {
    const rows = randomScreenShots
      ? getRowsWithAverages(userSummary?.random_screenshots)
      : getRowsWithAverages(userSummary?.image_activity);

    const data = randomScreenShots
      ? userSummary?.random_screenshots &&
        userSummary?.random_screenshots.length > 0
        ? userSummary.random_screenshots
        : [{ hour_slot: null, minute_to_add: "0", image: null, kpp: 0, mpp: 0 }]
      : userSummary?.image_activity && userSummary?.image_activity.length > 0
      ? userSummary.image_activity
      : [{ hour_slot: null, minute_to_add: "0", image: null, kpp: 0, mpp: 0 }];

    setImageActivityRows(rows);
    setDataToRender(data);
  }, [randomScreenShots, userSummary]);

  const handleDateClick = (day) => {
    const selectedDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      day
    );
    setDataByDate(formatDate(selectedDate));
    setIsDropdownOpen(false);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
  };

  return (
    <>
      {showModal && (
        <div className={styles.modalOverlay}>
          <div className={`${styles.modal}`}>
            <PreviewModal
              onClose={() => setShowModal(false)}
              imgSrc={imageUrl}
            />
          </div>{" "}
        </div>
      )}
      {isLoading ? (
        <Loader className="" />
      ) : (
        <>
          <div
            className={`${styles.main_Heading} ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Activty
          </div>
          <hr className="w-full" />
          <div
            className={` lg:w-5/6 w-full  ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            {isModalOpen && (
              <FilterModal onClose={() => setIsModalOpen(false)} />
            )}
            <div className={styles.container}>
              <div
                className={styles["calendar-header"]}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <div>
                  <span className={styles["month-div"]}>
                    <FaArrowLeft onClick={goToPrevMonth} />
                    <h2
                      className="cursor-pointer"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {currentDate.toLocaleString("default", { month: "long" })}{" "}
                      {currentDate.getFullYear()}
                    </h2>
                    <div
                      className={styles.dropdown}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      <IoMdArrowDropdown />
                      {isDropdownOpen && (
                        <div
                          className={`${styles.dropdownMenu} ${
                            theme === "dark"
                              ? "bg-black text-white"
                              : "bg-white text-black"
                          }`}
                        >
                          <h2 className="text-center p-2">
                            {currentDate.toLocaleString("default", {
                              month: "long",
                            })}{" "}
                            {currentDate.getFullYear()}
                          </h2>
                          <div className="flex justify-around">
                            {["S", "M", "T", "W", "T", "F", "S"].map((day) => (
                              <th
                                key={day}
                                className={` ${styles["day-header"]}`}
                              >
                                {day}
                              </th>
                            ))}
                          </div>
                          <div className={styles.calendar}>
                            {days.map((day, index) => (
                              <div
                              key={index}
                              onClick={() => {
                                if (day.currentMonth) {
                                  handleDateClick(day.day);
                                }
                              }}
                              className={`
                                ${theme === "dark" ? "hover:border-white" : "hover:border-black"} 
                                ${styles.day} 
                                ${day.currentMonth ? styles.currentMonth : styles.otherMonth} 
                                ${
                                  day.currentMonth &&
                                  dataByDate ===
                                    formatDate(
                                      new Date(
                                        currentDate.getFullYear(),
                                        currentDate.getMonth(),
                                        day.day
                                      )
                                    )
                                    ? styles.selected
                                    : ""
                                }
                                ${!day.currentMonth ? "cursor-not-allowed opacity-50" : ""}
                              `}
                            >
                              {day.day}
                            </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <FaArrowRight onClick={goToNextMonth} />
                  </span>
                  <div className={`${styles.flexRow}`}>
                    {/* <label className={styles.options}>
                      Select:
                      <select className={styles.selectBox} disabled={true}>
                        <option value="None">None</option>
                      </select>
                      <select className={styles.selectBox} disabled={true}>
                        <option value="Monitor">Monitor</option>
                      </select>
                    </label> */}
                    {/* <button onClick={() => setShowMore(!showMore)}>
                <VscGraph /> Show statistics
              </button> */}
                    <button
                      onClick={() =>
                        setShowOfflineActivity(!showOfflineActivity)
                      }
                    >
                      <FaRegLightbulb /> Show offline activity
                    </button>
                  </div>
                  <div className={`${styles.flexRow} w-56`}>
                    <button
                      onClick={() => setRandomScreenShots(!randomScreenShots)}
                    >
                      <FiActivity />{" "}
                      <span className={`${randomScreenShots ? "" : "text-red-600"}`}>Simple </span> / 
                      <span className={`${randomScreenShots ? "text-red-600" : ""}`}>Random </span>Screenshot
                    </button>
                  </div>
                </div>

                <div className={styles.productivity_main}>
                  <span>
                    Total day time:{" "}
                    {convertMinutesToHours(
                      userSummary?.total_tracked_time || "0"
                    )}
                  </span>
                  <span>
                    Active Time:{" "}
                    {convertMinutesToHours(userSummary?.active_time || "0")}{" "}
                    {`(${userSummary?.active_time_percentage || "0"}%)`}
                  </span>
                  <span className={styles.productivity_div}>
                    Productivity:{" "}
                    {(parseFloat(
                      userSummary?.keyboard_productivity_percentage || "0"
                    ) +
                      parseFloat(
                        userSummary?.mouse_productivity_percentage || "0"
                      )) /
                      2}
                    % (
                    <div className={styles.productivity_inner_div}>
                      <FaKeyboard />{" "}
                      {userSummary?.keyboard_productivity_percentage || "0"}%{" "}
                      <FaMouse />{" "}
                      {userSummary?.mouse_productivity_percentage || "0"}%{" "}
                    </div>
                    )
                  </span>
                </div>
                <div className={`text-left ${styles.user_main}`}>
                  <div className="flex gap-1 items-center ">
                    <span>User: </span>{" "}
                    {userInfo?.user?.role?.name !== "Time Reporter" ? (
                      <>
                        <div className={styles.custom_dropdown}>
                          <button
                            className={`${
                              theme === "dark" ? " text-white" : " text-black"
                            } ${styles.dropdown_button}`}
                            onClick={() => {
                              setIsOpen(!isOpen);
                            }}
                          >
                            {userId
                              ? `${
                                  users.find((user) => user.id === userId)
                                    ?.first_name || ""
                                } ${
                                  users.find((user) => user.id === userId)
                                    ?.last_name || ""
                                }`
                              : "Select"}{" "}
                            <IoIosArrowDown />
                          </button>
                          {isOpen && (
                            <ul
                              className={`${
                                theme === "dark"
                                  ? "bg-darkMode text-white"
                                  : "bg-white text-black"
                              } ${styles.dropdown_menu}`}
                            >
                              {users.map((data) => {
                                if (data?.role?.name === "Time Reporter") {
                                  return (
                                    <li
                                      key={data?.id}
                                      value={data?.id}
                                      onClick={() => {
                                        setUserId(data?.id);
                                        setIsOpen(false);
                                      }}
                                    >
                                      {data?.first_name} {data?.last_name}
                                    </li>
                                  );
                                }
                                return null;
                              })}
                            </ul>
                          )}
                        </div>
                      </>
                    ) : (
                      <span className="text-sm">
                        {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                      </span>
                    )}
                  </div>
                  <div className="flex gap-1 items-center">
                    <span>Project: </span>
                    <select
                      className="ml-2 text-black h-6 border border-black rounded-md"
                      disabled={true}
                    >
                      <option value="edit"> All</option>
                    </select>
                  </div>
                </div>

                {/* <span
                className={`${styles["month-div"]} cursor-pointer`}
                onClick={() => setIsModalOpen(true)}
              >
                <FaFilter />
                Filter off
                <IoMdArrowDropdown />
              </span> */}
              </div>
              {showOfflineActivity ? (
                <div className="w-full flex justify-center">
                  <OfflineActivityForm />
                </div>
              ) : (
                <div
                  className={`flex justify-center ${styles["activity-section"]}`}
                >
                  <div className={styles["table-container"]}>
                    <table className={styles["activity-table"]}>
                      <tbody>
                        <span
                          style={{
                            borderLeft: `1px solid ${
                              theme === "dark" ? "white" : "black"
                            }`,
                            paddingLeft: "20px",
                          }}
                        >
                          {imageActivityRows.map((activity) => (
                            <>
                              <tr
                                key={activity.id}
                                className={styles.table_row}
                              >
                                <td className={styles["activity-first-cell"]}>
                                  <div
                                    style={{
                                      backgroundColor: `${
                                        theme === "dark" ? "white" : "black"
                                      }`,
                                    }}
                                    className={styles.circleDiv}
                                  />
                                  <div className={styles.time_div1}>
                                    <span>{activity?.hour_slot}</span>
                                  </div>
                                  {randomScreenShots ? <></> :<div className={styles.time_div2}>
                                    +{activity?.totalMinutes}m
                                  </div>}
                                  
                                </td>
                              </tr>
                              {(() => {
                                let slotData = dataToRender.filter(
                                  (item) =>
                                    activity?.hour_slot
                                      ?.split(" ")[0]
                                      ?.split(":")[0] ===
                                    item?.hour_slot
                                      ?.split(" ")[0]
                                      ?.split(":")[0]
                                );

                                if (slotData.length < 6) {
                                  const sampleDataNeeded = Array.from(
                                    { length: 6 - slotData.length },
                                    (_, index) => ({
                                      minute_to_add: "0",
                                      image: null,
                                      kpp: 0,
                                      mpp: 0,
                                    })
                                  );

                                  slotData = [...slotData, ...sampleDataNeeded];
                                }

                                return (
                                  <tr
                                    className="flex gap-2 relative"
                                    style={{
                                      overflowX: "auto",
                                      maxWidth: "1015px",
                                    }}
                                  >
                                    {slotData
                                      .sort((a, b) => a.hour_slot - b.hour_slot)
                                      .map((data, index) => (
                                        <td key={index}>
                                          <div className="flex justify-center w-full">
                                            <span
                                              className={`${
                                                styles.platformDiv
                                              } ${
                                                theme === "dark"
                                                  ? "bg-[#2c2c2e]"
                                                  : "bg-lightMode "
                                              } `}
                                            >
                                              Platform (Development)
                                            </span>
                                          </div>
                                          <div
                                            className={` ${
                                               theme === "dark"
                                                ? "bg-[#2c2c2e] text-white shadow-sm"
                                                : "bg-lightMode text-black shadow-sm shadow-black" 
                                            } ${styles.cardContainer} `}
                                            onMouseLeave={() =>
                                              setOpenDropdownId(null)
                                            }
                                          >
                                            <div className="relative">
                                              {data.image ? (
                                                <>
                                                  <span
                                                    className={
                                                      styles.screenshotMain
                                                    }
                                                  >
                                                    <img
                                                      src={data?.image}
                                                      alt="Screenshot"
                                                      className={
                                                        styles.screenshot
                                                      }
                                                    />
                                                    <button
                                                      className={
                                                        styles.viewButton
                                                      }
                                                      onClick={() => {
                                                        setShowModal(true);
                                                        setImageUrl([
                                                          data?.image,
                                                          data?.image_one,
                                                          data?.image_two,
                                                          data?.image_three,
                                                          data?.image_four,
                                                        ]);
                                                      }}
                                                    >
                                                      View screens
                                                    </button>
                                                  </span>

                                                  <div className="relative">
                                                    <div
                                                      className={
                                                        styles.screenDiv
                                                      }
                                                      onClick={() =>
                                                        handleDropdownToggle(
                                                          data?.id
                                                        )
                                                      }
                                                    >
                                                      {(() => {
                                                        const screenCount = [
                                                          data?.image,
                                                          data?.image_one,
                                                          data?.image_two,
                                                          data?.image_three,
                                                          data?.image_four,
                                                        ].filter(
                                                          Boolean
                                                        ).length;

                                                        return screenCount === 1
                                                          ? "1 Screen"
                                                          : `+${
                                                              screenCount - 1
                                                            } Screens`;
                                                      })()}
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                <div
                                                  className={
                                                    styles.activity_img
                                                  }
                                                >
                                                  BugTrack agent was disabled
                                                </div>
                                              )}
                                            </div>
                                            {randomScreenShots ? <></> : <div className="px-2">
                                              <div className={styles.details}>
                                                <span className={styles.time}>
                                                  {data?.hour_slot}
                                                </span>
                                                <div
                                                  className={styles.time_main2}
                                                >
                                                  <div className="flex gap-1 items-center">
                                                    {data?.kpp}% <FaKeyboard />
                                                  </div>
                                                  <div className="flex gap-1 items-center">
                                                    {data?.mpp}% <FaMouse />
                                                  </div>
                                                </div>
                                              </div>

                                              <div
                                                className={`${
                                                  theme === "dark"
                                                    ? "bg-[#dddede]"
                                                    : "bg-[#a0a0a0]"
                                                } ${
                                                  styles.progressBarContainer
                                                }`}
                                              >
                                                <div
                                                  className={`${
                                                    Math.floor(
                                                      (parseFloat(
                                                        data?.kpp || "0"
                                                      ) +
                                                        parseFloat(
                                                          data?.mpp || "0"
                                                        )) /
                                                        2
                                                    ) < 50
                                                      ? "bg-red-500"
                                                      : Math.floor(
                                                          (parseFloat(
                                                            data?.kpp || "0"
                                                          ) +
                                                            parseFloat(
                                                              data?.mpp || "0"
                                                            )) /
                                                            2
                                                        ) < 70
                                                      ? "bg-orange-500"
                                                      : "bg-green-500"
                                                  } ${styles.progressBar}`}
                                                  style={{
                                                    width: `${
                                                      (parseFloat(
                                                        data?.kpp || "0"
                                                      ) +
                                                        parseFloat(
                                                          data?.mpp || "0"
                                                        )) /
                                                      2
                                                    }%`,
                                                  }}
                                                ></div>
                                                <span
                                                  className={
                                                    styles.progressText
                                                  }
                                                >
                                                  {(parseFloat(
                                                    data?.kpp || "0"
                                                  ) +
                                                    parseFloat(
                                                      data?.mpp || "0"
                                                    )) /
                                                    2}
                                                  % of {data?.minute_to_add}m
                                                </span>
                                              </div>
                                            </div>}
                                            
                                          </div>
                                        </td>
                                      ))}
                                  </tr>
                                );
                              })()}
                            </>
                          ))}
                        </span>
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Activity;
