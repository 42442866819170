import React, { useState } from "react";
import { FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./styles.module.css"; 
import { fetchApi } from '../../../utlis/axios';
import { useDispatch, useSelector } from "react-redux";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";

const ContactUs = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const dispatch = useDispatch()
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [id]: value,
    }));
  };

  const handleSubmit = async (e) => {    
    e.preventDefault();
    if (!formData.fullName || !formData.email || !formData.message) {
      toast.dismiss()
      return toast.error("All fields are required");
    }

    try {
      const response = await fetchApi.post('contact/', formData, {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (response.ok) {
        toast.dismiss()
        toast.success("Message sent successfully!");
        setFormData({
          fullName: "",
          email: "",
          message: "",
        });
      } else {
        toast.dismiss()
        toast.error("Failed to send message. Please try again later.");
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      toast.dismiss()
      toast.error("Something went wrong. Please try again later.");
    }
  };

  return (
    <>
    <style>{`
        .input,
  .textarea {
    width: 100%;
    padding: 0.75rem;
    border-radius: 0.25rem;
    background-color:${theme === 'dark' ? '#333333' : '#ffffff'} ;
    transform: scale(1.05);
    color:${theme === 'dark' ? '#ffffff' : '#000000'};
    border: none;
  }
  .textarea {
    height: 8rem;
    resize: none;
  }
  
    `}</style>
    <div className={styles.contactus} id="contactUs">
      <h2 className="main-heading">Contact Us</h2>
      <p className={styles.introText}>We'd love to hear from you</p>
      <div className={styles.content}>
        <div className={styles.formContainer}>
          <form className={`${styles.form} `} onSubmit={handleSubmit}>
            <div className={styles.formGroup}>
              <label htmlFor="fullName" className={styles.label}>
                Full Name
              </label>
              <input
                id="fullName"
                placeholder="Your full name"
                className="input "
                style={{border:"1px solid red"}}
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="email" className={styles.label}>
                Email Address
              </label>
              <input
                id="email"
                type="email"
                placeholder="Your email address"
                className="input"
                value={formData.email}
                onChange={handleInputChange}
                style={{border:"1px solid red"}}
              />
            </div>
            <div className={styles.formGroup}>
              <label htmlFor="message" className={styles.label}>
                Your Message
              </label>
              <textarea
                id="message"
                placeholder="Write your message"
                className="textarea"
                value={formData.message}
                onChange={handleInputChange}
                style={{border:"1px solid red"}}
              />
            </div>
            <button type="submit" className="heroButton">
              Send
            </button>
          </form>
        </div>
        <div className={styles.info}>
          <div className={styles.infoItem}>
            <FaEnvelope className={styles.contactIcon} />
            <div>
              <p className={styles.infoTitle}>Support Email</p>
              <p>support@bugtrack.com</p>
            </div>
          </div>

          <div className={styles.infoItem}>
            <FaPhone className={styles.contactIcon} />
            <div>
              <p className={styles.infoTitle}>Call Us</p>
              <p>+1 (123) 456-7890</p>
            </div>
          </div>

          <div className={styles.infoItem}>
            <FaMapMarkerAlt className={styles.contactIcon} />
            <div>
              <p className={styles.infoTitle}>Office Location</p>
              <p>123 Main Street, Anytown, USA</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactUs;
