import React, { useState } from "react";
import styles from "./hourlyRate.module.css";
import { useSelector } from "react-redux";
import { RxCross2 } from "react-icons/rx";

const HourlyRate = ({ setShowHourRate }) => {
  const { theme } = useSelector((state) => state.theme);
  const [onlineRate, setOnlineRate] = useState(0);
  const [offlineRate, setOfflineRate] = useState(0);
  const [currency, setCurrency] = useState("$");

  const handleUpdate = () => {
    // Handle the update logic
    console.log("Hourly rates updated:", { onlineRate, offlineRate, currency });
  };

  const handleCancel = () => {
    // Handle the cancel logic
    setOnlineRate(0);
    setOfflineRate(0);
    setCurrency("$");
  };

  return (
    <div className="relative">
      <span
        className="absolute -top-2 -right-1 text-lg cursor-pointer"
        onClick={() => setShowHourRate(false)}
      >
        <RxCross2 />
      </span>
      <h2 className={styles.label}>Hourly Rate</h2>
      <div className={styles.formGroup}>
        <label className={styles.label}>
          Online rate:
          <input
            type="number"
            value={onlineRate}
            onChange={(e) => setOnlineRate(e.target.value)}
            className={styles.input}
          />
          <span className={styles.example}>e.g. 5.3</span>
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Offline rate:
          <input
            type="number"
            value={offlineRate}
            onChange={(e) => setOfflineRate(e.target.value)}
            className={styles.input}
          />
          <span className={styles.example}>e.g. 5.3</span>
        </label>
      </div>

      <div className={styles.formGroup}>
        <label className={styles.label}>
          Currency:
          <input
            type="text"
            value={currency}
            onChange={(e) => setCurrency(e.target.value)}
            className={styles.input}
          />
          <span className={styles.example}>e.g. $</span>
        </label>
      </div>

      <div className="flex justify-end w-full mt-4">
        <button className="heroButton" onClick={handleUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default HourlyRate;
