import React, { useState } from "react";
import { useStripe, useElements,CardElement } from "@stripe/react-stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import styles from "./StripePayment.module.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import { toggleAuth } from "../../Redux/Slices/auth-slice";
import { useNavigate } from "react-router-dom";

const CheckoutForm = ({clientSecret}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useSelector((state) => state.theme);
  const { signupInfo } = useSelector((state) => state?.signUp || {});

  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) return;

    setIsLoading(true);

    const result = await elements.submit();
    if (result.error) {
      toast.error(result.error.message || "Invalid payment details.");
      setIsLoading(false);
      return;
    }
    console.log(clientSecret);
    
    const { setupIntent, error } = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        // Pass customer information here
        payment_method_data: {
          billing_details: {
            name: `${signupInfo.first_name} ${signupInfo.last_name}`,
            email: signupInfo.email,
          },
        },  
      },
      redirect: "if_required",
    });

    if (error) {
      setIsLoading(false)
      toast.dismiss()
      toast.error(error.message);
    } else if (setupIntent && setupIntent.status === "succeeded") {
      try {
        const userData = new FormData();
        userData.append("first_name", signupInfo.first_name);
        userData.append("last_name", signupInfo.last_name);
        userData.append("email", signupInfo.email);
        userData.append("quantity", signupInfo.quantity);
        userData.append("password", signupInfo.password);
        userData.append("role", signupInfo.role);
        userData.append("domain", signupInfo.domain);
        userData.append("plan", signupInfo.plan);
        userData.append("session_key", setupIntent?.id);

        const result = await fetchApi.post("register/", userData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (result.status === 200) {          
          dispatch(toggleAuth({ isLogin: true, userInfo: result.data }));
          toast.dismiss()
          toast.success("Account created successfully!");
          navigate("/registercompany");
        }
      } catch (error) {
        const errorMessage =
  error?.response?.data?.error ||
  error?.response?.data?.email?.[0] ||
  error?.response?.data?.[0] ||
  "Registration failed";

          toast.dismiss()
          navigate("/signup");
        toast.error(errorMessage);
        console.log(error);
      }
    }

    setIsLoading(false);
  };

  return (
    <div
      className={`${styles.checkoutContainer} ${
        theme === "dark" ? styles.darkMode : ""
      }`}
    >
      <form onSubmit={handleSubmit} className={styles.cardForm}>
        <h2 className={styles.formTitle}>Complete Your Payment</h2>
        <PaymentElement />
        <button
          className={`heroButton w-full ${isLoading ? styles.loading : ""}`}
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Loading..." : "Submit"}
        </button>
      </form>
    </div>
  );
};

export default CheckoutForm;
