import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  companyInfo: {
  },
};

const regcompanySlice = createSlice({
  name: 'registerCompany',
  initialState,
  reducers: {
    toggleCompany: (state, { payload }) => {
      state.companyInfo = payload?.companyInfo;
    },
  },
});

export const { toggleCompany } = regcompanySlice.actions;

export default regcompanySlice.reducer;
