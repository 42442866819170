import React, { useEffect, useState } from "react";
import { Bar, Line } from "react-chartjs-2";
import styles from "./analyticsAndCharts.module.css";
import { IoIosArrowDown } from "react-icons/io";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler,
} from "chart.js";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  Filler
);

const AnalyticsAndCharts = () => {
  const { theme } = useSelector((state) => state?.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [months, setMonths] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [weeks, setWeeks] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState([]);
  const [showWeek, setShowWeek] = useState();
  const [reportData, setReportData] = useState(null);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [openMonthOption, setOpenMonthOption] = useState(false);
  const [openWeekOption, setOpenWeekOption] = useState(false);

  const getCurrentWeekDateRange = () => {
    const today = new Date();
    const firstDayOfWeek = new Date(
      today.setDate(today.getDate() - today.getDay() + 1)
    );
    const lastDayOfWeek = new Date(firstDayOfWeek);
    lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);

    const formatDate = (date) => {
      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      return `${year}-${month.toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
    };

    return [formatDate(firstDayOfWeek), formatDate(lastDayOfWeek)];
  };

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        if (userInfo?.user?.role?.name === "Time Reporter") {
          setUserId(userInfo?.user?.id);
        } else {
          const result = response?.data?.results?.filter(
            (item) =>
              item.role.name === "Time Reporter" &&
              item.domain === userInfo.user.domain
          );
          if (result.length === 0) {
            setUserId(userInfo?.user?.id);
          } else {
            setUserId(result?.[0]?.id);
          }
          setUsers(result);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      console.log(error);
      setIsLoading(false);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  const getPreviousMonths = () => {
    const now = new Date();
    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    const joiningDate = new Date(userInfo?.user?.created_at);
    const joiningYear = joiningDate.getFullYear();
    const joiningMonth = joiningDate.getMonth();

    const monthsArray = [];

    for (let i = currentMonth; i >= 0; i--) {
      const date = new Date(currentYear, i, 1);
      if (
        date.getFullYear() > joiningYear ||
        (date.getFullYear() === joiningYear && date.getMonth() >= joiningMonth)
      ) {
        monthsArray.push(
          date.toLocaleString("default", { month: "long", year: "numeric" })
        );
      }
    }

    setMonths(monthsArray);
  };

  const getWeeksOfMonth = (month) => {
    const [monthName, year] = month.split(" ");
    const monthIndex = new Date(
      Date.parse(`${monthName} 1, ${year}`)
    ).getMonth();
    const firstDayOfMonth = new Date(year, monthIndex, 1);
    const lastDayOfMonth = new Date(year, monthIndex + 1, 0);
    let firstMonday = new Date(firstDayOfMonth);
    while (firstMonday.getDay() !== 1) {
      firstMonday.setDate(firstMonday.getDate() + 1);
    }
    const weeksArray = [];
    let startOfWeek = new Date(firstMonday);

    if (firstDayOfMonth < firstMonday) {
      const previousSunday = new Date(firstMonday);
      previousSunday.setDate(firstMonday.getDate() - 1);
      const previousMonthStart = new Date(firstMonday);
      previousMonthStart.setDate(previousSunday.getDate() - 6);
      const weekRange = `${previousMonthStart.getDate()}-${
        previousMonthStart.getMonth() + 1
      }-${previousMonthStart.getFullYear()} to ${previousSunday.getDate()}-${
        previousSunday.getMonth() + 1
      }-${previousSunday.getFullYear()}`;
      weeksArray.push(weekRange);
    }

    while (startOfWeek <= lastDayOfMonth) {
      const endOfWeek = new Date(startOfWeek);
      endOfWeek.setDate(startOfWeek.getDate() + 6);

      if (endOfWeek > lastDayOfMonth) {
        const nextMonthSunday = new Date(endOfWeek);
        nextMonthSunday.setMonth(monthIndex + 1);
        nextMonthSunday.setDate(6 - lastDayOfMonth.getDay());

        const weekRange = `${startOfWeek.getDate()}-${
          startOfWeek.getMonth() + 1
        }-${startOfWeek.getFullYear()} to ${nextMonthSunday.getDate()}-${
          nextMonthSunday.getMonth() + 1
        }-${nextMonthSunday.getFullYear()}`;
        weeksArray.push(weekRange);
      } else {
        const weekRange = `${startOfWeek.getDate()}-${
          startOfWeek.getMonth() + 1
        }-${startOfWeek.getFullYear()} to ${endOfWeek.getDate()}-${
          endOfWeek.getMonth() + 1
        }-${endOfWeek.getFullYear()}`;
        weeksArray.push(weekRange);
      }

      startOfWeek.setDate(startOfWeek.getDate() + 7);
    }

    setWeeks(weeksArray);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(e);
    getWeeksOfMonth(e);
  };

  const handleWeekChange = (e) => {
    const dateRange = e.split(" to ");

    const convertToISOFormat = (dateStr) => {
      const [day, month, year] = dateStr.split("-");
      return `${year}-${month}-${day}`;
    };

    const formattedDates = dateRange.map(convertToISOFormat);

    setSelectedWeek(formattedDates);
    setShowWeek(e);
  };

  const getData = async () => {
    if (userId) {
      setIsLoading(true);
      try {
        const result = await fetchApi.post(
          "report/",
          {
            users: [userId],
            domain: userInfo.user.domain,
            report_type: "daily",
            date_range: selectedWeek,
          },
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );
        if (result.status === 200) {
          setReportData(result.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          toast.dismiss();
          toast.error("Your session is expired");
          return;
        }
        console.log(error);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getUsers();
    getPreviousMonths();
    setSelectedWeek(getCurrentWeekDateRange());
  }, []);

  useEffect(() => {
    getData();
  }, [selectedWeek, userId]);

  const generateDateRange = (start, end) => {
    const dateArray = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      dateArray.push(currentDate.toISOString().split("T")[0]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateArray;
  };

  const formatChartData = (dateRange, dataKey) => {
    return dateRange.map((date) => {
      let dateData = null;
      for (let key in reportData) {
        if (reportData[key]?.[date]) {
          dateData = reportData[key][date];
          break;
        }
      }

      if (dateData && dateData[dataKey] != null) {
        return dateData[dataKey];
      }

      return 0;
    });
  };

  const dateRangeLabels =
    selectedWeek.length === 2
      ? generateDateRange(selectedWeek[0], selectedWeek[1])
      : [];

  const trackedTimeData = {
    labels: dateRangeLabels,
    datasets: [
      {
        label: "Total Tracked Time (hours)",
        data: formatChartData(dateRangeLabels, "total_tracked_time"),
        backgroundColor: "#4caf50",
      },
    ],
  };
  const chartOptions = {
    responsive: true,
    // maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            const totalMinutes = tooltipItem.raw;
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;
            return `${hours}:${minutes.toString().padStart(2, "0")} hours`;
          },
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            const hours = Math.floor(value / 60);
            const minutes = value % 60;
            return `${hours}:${minutes.toString().padStart(2, "0")}`;
          },
        },
      },
    },
  };

  const productivityData = {
    labels: dateRangeLabels,
    datasets: [
      {
        label: "Keyboard Productivity",
        data: formatChartData(
          dateRangeLabels,
          "keyboard_productivity_percentage"
        ),
        fill: false,
        backgroundColor: "#fbff00",
        tension: 0.1,
      },
      {
        label: "Mouse Productivity",
        data: formatChartData(dateRangeLabels, "mouse_productivity_percentage"),
        fill: false,
        backgroundColor: "#2196f3",
        tension: 0.1,
      },
    ],
  };

  return (
    <>
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Analytics and Charts
      </div>
      <hr className="w-full" />
      <div
        className={`${styles.outlet}  w-full h-full ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div
              className="flex justify-center items-center gap-3 py-2"
              onMouseLeave={() => {
                setIsOpen(false);
                setOpenMonthOption(false);
                setOpenWeekOption(false);
              }}
            >
              <div className="flex gap-1 items-center space-x-2">
                <span className="text-sm">User:</span>
                {userInfo?.user?.role?.name !== "Time Reporter" ? (
                  <>
                    <div className={styles.custom_dropdown}>
                      <button
                        className={`${
                          theme === "dark" ? " text-white" : " text-black"
                        } ${styles.dropdown_button}`}
                        onClick={() => {
                          setIsOpen(!isOpen);
                          setOpenMonthOption(false);
                          setOpenWeekOption(false);
                        }}
                      >
                        {userId
                          ? `${
                              users.find((user) => user.id === userId)
                                ?.first_name || ""
                            } ${
                              users.find((user) => user.id === userId)
                                ?.last_name || ""
                            }`
                          : "Select"}{" "}
                          <IoIosArrowDown/>
                      </button>
                      {isOpen && (
                        <ul
                          className={`${
                            theme === "dark"
                              ? "bg-darkMode text-white"
                              : "bg-white text-black"
                          } ${styles.dropdown_menu}`}
                        >
                          {users.map((data) => {
                            if (data?.role?.name === "Time Reporter") {
                              return (
                                <li
                                  key={data?.id}
                                  value={data?.id}
                                  onClick={() => {
                                    setUserId(data?.id);
                                    setIsOpen(false);
                                  }}
                                >
                                  {data?.first_name} {data?.last_name}
                                </li>
                              );
                            }
                            return null;
                          })}
                        </ul>
                      )}
                    </div>
                  </>
                ) : (
                  <span className="text-sm">
                    {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                  </span>
                )}
              </div>

              <div className="flex gap-1 items-center space-x-2 ">
                <div className={styles.custom_dropdown}>
                  <button
                    className={`${
                      theme === "dark" ? " text-white" : " text-black"
                    } ${styles.dropdown_button}`}
                    onClick={() => {
                      setOpenMonthOption(!openMonthOption);
                      setIsOpen(false);
                      setOpenWeekOption(false);
                    }}
                  >
                    {selectedMonth ? selectedMonth : "Select Month"}{" "}<IoIosArrowDown/>
                  </button>
                  {openMonthOption && (
                    <ul
                      className={`${
                        theme === "dark"
                          ? "bg-darkMode text-white"
                          : "bg-white text-black"
                      } ${styles.dropdown_menu}`}
                    >
                      {months.map((month, index) => (
                        <li
                          key={index}
                          value={month}
                          onClick={() => {
                            handleMonthChange(month);
                            setOpenMonthOption(!openMonthOption);
                          }}
                        >
                          {month}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {selectedMonth && (
                  <div className={styles.custom_dropdown}>
                    <button
                      className={`${
                        theme === "dark" ? " text-white" : " text-black"
                      } ${styles.dropdown_button}`}
                      onClick={() => {
                        setOpenWeekOption(!openWeekOption);
                        setIsOpen(false);
                        setOpenMonthOption(false);
                      }}
                    >
                      {showWeek ? showWeek : "Select Week"}{" "}<IoIosArrowDown/>
                    </button>
                    {openWeekOption && (
                      <ul
                        className={`${
                          theme === "dark"
                            ? "bg-darkMode text-white"
                            : "bg-white text-black"
                        } ${styles.dropdown_menu}`}
                      >
                        {weeks.map((week, index) => (
                          <li
                            key={index}
                            value={week}
                            onClick={() => {
                              handleWeekChange(week);
                              setOpenWeekOption(!openWeekOption);
                            }}
                          >
                            {week}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className={`${styles.container} mt-3`}>
              <div
                className={`${styles.chartWrapper} p-2 m-1 ${
                  theme === "dark"
                    ? "bg-[#2c2c2e] text-white"
                    : "bg-lightMode text-black"
                }`}
              >
                <div className={`${styles.chartTitle} text-sm`}>
                  Weekly Hours Worked
                </div>
                <Bar
                  data={trackedTimeData}
                  options={chartOptions}
                  height={200}
                />
              </div>

              <div
                className={`${styles.chartWrapper} p-2 m-1 ${
                  theme === "dark"
                    ? "bg-[#2c2c2e] text-white"
                    : "bg-lightMode text-black"
                }`}
              >
                <div className={`${styles.chartTitle} text-sm`}>
                  Weekly Productivity
                </div>
                <Bar data={productivityData} height={200} />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnalyticsAndCharts;
