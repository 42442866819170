import React, { useState } from "react";
import BackBtn from "../backBtn/BackBtn";
import { useSelector } from "react-redux";
import { fetchApi } from "../../utlis/axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ParticlesBackground from "../home/ParticalsBackground";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ForgotPassword = () => {
  const { theme } = useSelector((state) => state.theme);
  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [userId, setUserId] = useState("");
  const [token, setToken] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState(0);


  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++; 
    if (/[A-Z]/.test(password)) strength++; 
    if (/[a-z]/.test(password)) strength++; 
    if (/\d/.test(password)) strength++; 
    if (/[@$!%*?&]/.test(password)) strength++; 
    setPasswordStrength(strength);
  };

  const handleSendEmail = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetchApi.post("forget-password/", {
        email: email,
      });
      console.log(response);

      if (response.status === 200) {
        setIsLoading(false);
        setStep("otp");
      }
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error?.response?.data?.email[0] || "Something went wrong";
      toast.dismiss();
      toast.error(errorMessage);
      console.log(error);
    }
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetchApi.post("forgot-password/verify-otp/", {
        email: email,
        otp: otp,
      });
      console.log(response);
      if (response.status === 200) {
        setUserId(response.data.user.id);
        console.log(response);
        setToken(response.data.access_token);
        setStep("newPassword");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      const errorMessage =
        error.response.data.otp ||
        error.response.data.error ||
        "Something went wrong";
      toast.dismiss();
      toast.error(errorMessage);
      console.log(error);
    }
  };

  const handleSetNewPassword = async (e) => {
    e.preventDefault();
    if (newPassword.length < 8) {
      toast.dismiss();
      toast.error("Password must be at least 8 characters!");
      return;
    }
    setIsLoading(true);
    try {
      const response = await fetchApi.post(
        "change-password/",
        {
          user_id: userId,
          password: newPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);

      if (response.status === 200) {
        setIsLoading(false);
        toast.dismiss();
        toast.success("Password changed successfully");
        navigate("/your-domain-name/login");
      }
    } catch (error) {
      setIsLoading(false);
      toast.dismiss();
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  return (
    <>
      <style>{`
        .login-box {
          background-color: ${
            theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"
          };
          color: ${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color: ${theme === "dark" ? "#444" : "#ffffff"};
          color: ${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link {
          color: ${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-text {
          color: ${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color: ${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <BackBtn bgColor={"red"} />
      <div className="login-container">
        <div className="login-box">
          <h2 className="main-heading text-center">Forgot Password</h2>

          {step === "email" && (
            <>
              <form className="input-group" onSubmit={handleSendEmail}>
                <label
                  className={`input-label ${
                    theme === "dark" ? "text-gray-400" : "text-black"
                  }`}
                >
                  Enter your email
                </label>
                <input
                  type="email"
                  placeholder="Enter your email"
                  className="input-field"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <button
                  className="heroButton w-full mt-3"
                  type="submit"
                  disable={isLoading}
                >
                  {isLoading ? "Loading..." : "Send me email"}
                </button>
              </form>
              <p className="terms-text">
                A note about spam filters: If you don't get an email from us
                within a few minutes please be sure to check your spam filter.
                The email will be coming from do-not-reply@bugtrack.com.
              </p>
            </>
          )}

          {step === "otp" && (
            <>
              <form className="input-group" onSubmit={handleVerifyOtp}>
                <label
                  className={`input-label ${
                    theme === "dark" ? "text-gray-400" : "text-black"
                  }`}
                >
                  Enter the OTP sent to your email
                </label>
                <input
                  type="text"
                  placeholder="Enter OTP"
                  className="input-field"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  required
                />
                <button
                  className="heroButton w-full mt-3"
                  type="submit"
                  disable={isLoading}
                >
                  {isLoading ? "Loading..." : "Verify OTP "}
                </button>
              </form>
              <p className="terms-text">
                A note about spam filters: If you don't get an email from us
                within a few minutes please be sure to check your spam filter.
                The email will be coming from do-not-reply@bugtrack.com.
              </p>
            </>
          )}

          {step === "newPassword" && (
            <>
              <form className="input-group" onSubmit={handleSetNewPassword}>
                <label
                  className={`input-label ${
                    theme === "dark" ? "text-gray-400" : "text-black"
                  }`}
                >
                  Enter your new password
                </label>
                <div className="relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    name="password"
                    style={{ paddingRight: "35px" }}
                    placeholder="Enter new password"
                    className="input-field"
                    value={newPassword}
                    onChange={(e) => {
                      const password = e.target.value;
                      setNewPassword(password);
                      calculatePasswordStrength(password);
                    }}
                    required
                  />
                  <div
                    className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </div>
                  <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                    <div
                      className={`h-full rounded-full transition-all duration-300 ${
                        passwordStrength < 2
                          ? "bg-red-500"
                          : passwordStrength < 4
                          ? "bg-orange-500"
                          : "bg-green-500"
                      }`}
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>
                <button
                  className="heroButton w-full mt-3"
                  type="submit"
                  disable={isLoading}
                >
                  {isLoading ? "Loading..." : "Submit"}
                </button>
              </form>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
