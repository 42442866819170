import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowBack } from "react-icons/io";

const BackBtn = ({ bgColor }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); 
  };

  return (
    <button 
      onClick={handleBack} 
      style={{
        position: 'fixed',
        top: '30px',
        left: '30px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px',
        fontSize: '20px',
        backgroundColor: bgColor,
        color: '#fff',
        border: 'none',
        borderRadius: '50%',
        cursor: 'pointer',
        outline: 'none',
        zIndex:'9999'
      }}
    >
      <IoIosArrowBack />
    </button>
  );
};

export default BackBtn;
