import React, { useEffect, useRef, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { IoMdArrowDropdown } from "react-icons/io";
import styles from "./summary.module.css";
import Tooltip from "../../tooltip/Tooltip";
import { IoIosArrowDown } from "react-icons/io";

import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../../Loader/Loader";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { togglesummaryData } from "../../../Redux/Slices/summaryData-slice";
import { useNavigate } from "react-router-dom";

const Summary = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [currentDate, setCurrentDate] = useState(new Date());
  const [days, setDays] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [users, setUsers] = useState([]);
  const [userId, setUserId] = useState("");
  const [userSummary, setUserSummary] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch();
  const [matchingEntry, setMatchingEntry] = useState();
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdownId((prevId) => (prevId === dropdownId ? null : dropdownId));
  };

  const joiningDate = new Date(userInfo?.user?.created_at);

  const getUsers = async () => {
    setIsloading(true);

    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        if (userInfo?.user?.role?.name === "Time Reporter") {
          setUserId(userInfo?.user?.id);
        } else {
          const result = response?.data?.results?.filter(
            (item) =>
              item.role.name === "Time Reporter" &&
              item.domain === userInfo.user.domain
          );
          if (result.length === 0) {
            setUserId(userInfo?.user?.id);
          } else {
            setUserId(result?.[0]?.id);
          }
          setUsers(result);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      console.log(error);
      setIsloading(false);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getSummaryData = async () => {
    if (userId && startDate && endDate) {
      setIsloading(true);
      try {
        const response = await fetchApi.get(
          `user-summaries/?user=${userId}&date__gte=${startDate}&date__Ite=${endDate}`,
          {
            headers: {
              Authorization: `Bearer ${userInfo.access_token}`,
            },
          }
        );
        if (response.status === 200) {
          setIsloading(false);
          setUserSummary(response?.data?.results);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          dispatch(toggleAuth({ isLogin: false, userInfo: null }));
          toast.dismiss();
          toast.error("Your session is expired");
          return;
        }
        setIsloading(false);
        console.log("Error while call user-summaries API: ", error);
        toast.dismiss();
        toast.error("Something went wrong");
      }
    }
  };

  useEffect(() => {
    getSummaryData();
  }, [userId, startDate, endDate]);

  useEffect(() => {
    generateCalendar(currentDate);
  }, [currentDate]);

  function formatToCustomISOString(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}T00:00:00Z`;
  }
  const generateCalendar = (date) => {
    const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const lastDayOfPrevMonth = new Date(
      date.getFullYear(),
      date.getMonth(),
      0
    ).getDate();

    const daysInMonth = [];
    for (let i = 1; i <= lastDayOfMonth.getDate(); i++) {
      daysInMonth.push({
        day: i,
        currentMonth: true,
      });
    }

    for (let i = firstDayOfMonth.getDay() - 1; i >= 0; i--) {
      daysInMonth.unshift({
        day: lastDayOfPrevMonth - i,
        currentMonth: false,
      });
    }

    const totalDays = daysInMonth.length;
    for (let i = 1; totalDays + i <= 35; i++) {
      daysInMonth.push({
        day: i,
        currentMonth: false,
      });
    }

    setDays(daysInMonth);

    const startDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      1 - firstDayOfMonth.getDay()
    );

    const endDate = new Date(
      startDate.getFullYear(),
      startDate.getMonth(),
      startDate.getDate() + daysInMonth.length - 1
    );

    setStartDate(formatToCustomISOString(startDate));
    setEndDate(formatToCustomISOString(endDate));
  };

  const goToPrevMonth = () => {
    const prevMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - 1,
      1
    );
    if (
      prevMonth.getFullYear() > joiningDate.getFullYear() ||
      (prevMonth.getFullYear() === joiningDate.getFullYear() &&
        prevMonth.getMonth() >= joiningDate.getMonth())
    ) {
      setCurrentDate(prevMonth);
    }
  };

  const goToNextMonth = () => {
    const nextMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      1
    );
    const today = new Date();
    if (nextMonth <= today) {
      setCurrentDate(nextMonth);
    }
  };

  const renderCalendarRows = () => {
    const rows = [];
    for (let i = 0; i < days.length; i += 7) {
      rows.push(days.slice(i, i + 7));
    }
    return rows;
  };

  const getSelectableMonths = () => {
    const selectableMonths = [];
    const currentYear = currentDate.getFullYear();
    const currentMonth = new Date().getMonth();

    for (let month = joiningDate.getMonth(); month <= currentMonth; month++) {
      selectableMonths.push(new Date(currentYear, month, 1));
    }

    return selectableMonths;
  };

  const handleMonthSelect = (month) => {
    setCurrentDate(month);
    setIsDropdownOpen(false);
  };

  const convertMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, "0")}`;
  };

  const getTotalTrackedTimeForDate = (date) => {
    // Format the input date to "YYYY-MM-DD" using local time
    const formattedDate = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

    // Ensure userSummary is an array before using .find()
    if (!Array.isArray(userSummary)) {
      console.error("userSummary is not an array:", userSummary);
      return "";
    }

    // Find the summary in userSummary where the date matches the formattedDate
    const summary = userSummary.find(
      (item) => item.date.split("T")[0] === formattedDate
    );
    return summary ? convertMinutesToHours(summary.total_tracked_time) : "";
  };

  const calculateWeeklyTotal = (week) => {
    const totalMinutes = week.reduce((sum, day) => {
      if (day.currentMonth) {
        const trackedTime = getTotalTrackedTimeForDate(
          new Date(currentDate.getFullYear(), currentDate.getMonth(), day.day)
        );
        if (trackedTime) {
          const [hours, minutes] = trackedTime.split(":").map(Number);
          return sum + hours * 60 + minutes;
        }
      }
      return sum;
    }, 0);

    return convertMinutesToHours(totalMinutes);
  };

  const handleGetActivity = (summary) => {
    dispatch(
      togglesummaryData({
        summaryDate: summary?.date.substring(0, 10),
        summaryUserId: summary?.user?.id,
      })
    );
    navigate("/dashboard/activity");
  };

  return (
    <>
      {isLoading ? (
        <Loader styles={{ height: "100%" }} />
      ) : (
        <>
          <div
            className={`${styles.main_Heading} ${
              theme === "dark" ? "text-white" : "text-black"
            }`}
          >
            Summary
          </div>
          <hr className="w-full" />
          <div className={`lg:w-5/6 w-full ${styles.outlet}`}>
            <div
              className={`${styles["calendar-section"]} ${
                theme === "dark" ? "text-white" : "text-black"
              }`}
            >
              <div className="w-full flex justify-center">
                <div className={styles["calendar-header"]}>
                  <span className={styles["month-div"]}>
                    <FaArrowLeft onClick={goToPrevMonth} />
                    <h2
                      className="cursor-pointer"
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {currentDate.toLocaleString("default", { month: "long" })}{" "}
                      {currentDate.getFullYear()}
                    </h2>
                    <div
                      className={styles.dropdown}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      onMouseLeave={() => setIsDropdownOpen(false)}
                    >
                      <IoMdArrowDropdown />
                      {isDropdownOpen && (
                        <ul className={styles.dropdownMenu}>
                          {getSelectableMonths().map((month) => (
                            <li
                              key={month.getMonth()}
                              onClick={() => handleMonthSelect(month)}
                            >
                              {month.toLocaleString("default", {
                                month: "long",
                              })}
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                    <FaArrowRight onClick={goToNextMonth} />
                  </span>
                  <div className="flex space-x-4 items-center">
                    <Tooltip content="Up Coming">
                      <div className="flex gap-1 items-center">
                        <span>Projects:</span>
                        <select className="ml-2 text-black h-6" disabled={true}>
                          <option value="edit"> All</option>
                        </select>
                      </div>
                    </Tooltip>
                    <div className="flex gap-1 items-center ">
                      <span>User: </span>{" "}
                      {userInfo?.user?.role?.name !== "Time Reporter" ? (
                        <>
                          <div className={styles.custom_dropdown}>
                            <button
                              className={`${
                                theme === "dark" ? " text-white" : " text-black"
                              } ${styles.dropdown_button}`}
                              onClick={() => {
                                setIsOpen(!isOpen);
                              }}
                            >
                              {userId
                                ? `${
                                    users.find((user) => user.id === userId)
                                      ?.first_name || ""
                                  } ${
                                    users.find((user) => user.id === userId)
                                      ?.last_name || ""
                                  }`
                                : "Select"}{" "}
                              <IoIosArrowDown />{" "}
                            </button>
                            {isOpen && (
                              <ul
                                className={`${
                                  theme === "dark"
                                    ? "bg-darkMode text-white"
                                    : "bg-white text-black"
                                } ${styles.dropdown_menu}`}
                              >
                                {users.map((data) => {
                                  if (data?.role?.name === "Time Reporter") {
                                    return (
                                      <li
                                        key={data?.id}
                                        value={data?.id}
                                        onClick={() => {
                                          setUserId(data?.id);
                                          setIsOpen(false);
                                        }}
                                      >
                                        {data?.first_name} {data?.last_name}
                                      </li>
                                    );
                                  }
                                  return null;
                                })}
                              </ul>
                            )}
                          </div>
                        </>
                      ) : (
                        <span className="text-sm">
                          {userInfo?.user?.first_name}{" "}
                          {userInfo?.user?.last_name}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles["calendar-inner-section"]}>
                <div
                  className={`flex justify-center ${styles["calendar-section"]}`}
                >
                  <table
                    className={` ${styles["calendar-table"]} ${
                      theme === "dark" ? "text-white" : "text-black"
                    }`}
                  >
                    <thead>
                      <tr>
                        {[
                          "Sun",
                          "Mon",
                          "Tue",
                          "Wed",
                          "Thu",
                          "Fri",
                          "Sat",
                          "Week",
                        ].map((day) => (
                          <th key={day} className={` ${styles["day-header"]}`}>
                            {day}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {renderCalendarRows().map((week, index) => (
                        <tr key={index}>
                          {week.map((day, i) => (
                            <td
                              key={i}
                              className={`${styles["calendar-cell"]} `}
                              onMouseLeave={() => setOpenDropdownId(null)}
                            >
                              <div className={styles["calendar-inner-cell"]}>
                                <span>{day.day}</span>
                                <span
                                  className="text-bold cursor-pointer text-red-800"
                                  onClick={() => {
                                    const selectedDate = new Date(currentDate);
                                    selectedDate.setDate(day.day);
                                    const formattedDate = selectedDate
                                      .toISOString()
                                      .split("T")[0];
                                    const matchingEntry = userSummary.find(
                                      (data) => {
                                        const summaryDate = new Date(data.date)
                                          .toISOString()
                                          .split("T")[0];
                                        return (
                                          summaryDate === formattedDate &&
                                          data.user.id === userId
                                        );
                                      }
                                    );
                                    handleDropdownToggle(day);
                                    setMatchingEntry(matchingEntry);
                                  }}
                                >
                                  {day.currentMonth
                                    ? getTotalTrackedTimeForDate(
                                        new Date(
                                          currentDate.getFullYear(),
                                          currentDate.getMonth(),
                                          day.day
                                        )
                                      )
                                    : ""}
                                </span>
                                {openDropdownId === day && (
                                  <div className={styles.trackedTimeDropDown}>
                                    {userInfo?.user?.role?.name !==
                                    "Time Reporter" ? (
                                      <div className="flex items-center gap-3 text-base">
                                        <span>
                                          {
                                            users.find(
                                              (data) => data?.id === userId
                                            )?.first_name
                                          }{" "}
                                          {
                                            users.find(
                                              (data) => data?.id === userId
                                            )?.last_name
                                          }
                                        </span>
                                        <span className="text-bold cursor-pointer">
                                          {day.currentMonth
                                            ? getTotalTrackedTimeForDate(
                                                new Date(
                                                  currentDate.getFullYear(),
                                                  currentDate.getMonth(),
                                                  day.day
                                                )
                                              )
                                            : ""}
                                        </span>
                                        <span
                                          className="text-gray-500 cursor-pointer"
                                          onClick={() =>
                                            handleGetActivity(matchingEntry)
                                          }
                                        >
                                          (
                                          {matchingEntry
                                            ? matchingEntry.active_time
                                            : "No active time"}
                                          )
                                        </span>
                                      </div>
                                    ) : (
                                      <div className="flex items-center gap-3">
                                        <span>
                                          {userInfo?.user?.first_name}{" "}
                                          {userInfo?.user?.last_name}
                                        </span>
                                        <span className="text-bold cursor-pointer">
                                          {day.currentMonth
                                            ? getTotalTrackedTimeForDate(
                                                new Date(
                                                  currentDate.getFullYear(),
                                                  currentDate.getMonth(),
                                                  day.day
                                                )
                                              )
                                            : ""}
                                        </span>
                                        <span
                                          className="text-gray-500 cursor-pointer"
                                          onClick={() =>
                                            handleGetActivity(matchingEntry)
                                          }
                                        >
                                          (
                                          {matchingEntry
                                            ? matchingEntry.active_time
                                            : "No active time"}
                                          )
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                          ))}
                          <td
                            className={`${styles["calendar-cell"]} ${styles.result}`}
                          >
                            <span className="text-red-800">
                              {calculateWeeklyTotal(week)}
                            </span>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Summary;
