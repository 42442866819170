import React from "react";
import { useSelector } from "react-redux";

const Loader = () => {
  const { theme } = useSelector((state) => state.theme);

  return (
    <>
      <style>{`
    
.dot1 {
  width: 15px;
  height: 15px;
  background: ${theme === "dark" ? "#d90a2c" : "#000000"};
  animation: ScaleInOut 0.6s ease-in-out 0.2s infinite alternate;
}

.dot2 {
  width: 15px;
  height: 15px;
  background: ${theme === "dark" ? "#d90a2c" : "#000000"};
  animation: ScaleInOut 0.6s ease-in-out 0.4s infinite alternate;
}

.dot3 {
  width: 15px;
  height: 15px;
  background: ${theme === "dark" ? "#d90a2c" : "#000000"};
  animation: ScaleInOut 0.6s ease-in-out 0.6s infinite alternate;
}
@keyframes ScaleInOut {
  0% {
    transform: scale(0.8);
  }

  100% {
    transform: scale(1.5);
  }
}
    `}</style>

      <div className="w-full min-h-[90%] flex items-center justify-center mt-5">
        <div className="dot1 rounded-full m-2"></div>
        <div className="dot2 rounded-full m-2"></div>
        <div className="dot3 rounded-full m-2"></div>
      </div>
    </>
  );
};

export default Loader;
