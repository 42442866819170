import React from "react";
import AnimationLottie from "../../../lottie/animation-lottie";
import productivityAnimation from "../../../lottie/productivityAnimation.json";
import styles from "./styles.module.css"; 
import { useSelector } from "react-redux";

const BoostProductivity = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <section className={styles.heroSection}>
      <div className={styles.heroContainer}>
        <div className={styles.heroAnimation}>
          <AnimationLottie animationPath={productivityAnimation} />
        </div>
        <div className={styles.heroText}>
          <h2 className="main-heading">Boost Productivity</h2>
          <div className="flex flex-col gap-5">
            <div className={`${styles.productivityText}`}>
              <h6 className="sub-heading">Daily, Weekly, Monthly Reports</h6>
              <span>Generate insightful reports to analyze productivity.</span>
            </div>
            <div className={`${styles.productivityText} `}>
              <h6 className="sub-heading">Tool Integrations</h6>
              <span>Sync with calendars and other tools.</span>
            </div>
            <div className={`${styles.productivityText} `}>
              <h6 className="sub-heading">Real-time Notifications</h6>
              <span>Receive alerts for important updates.</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BoostProductivity;
