import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styles from "./user.module.css";
import profile from "../../../assets/face1.jpg";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import Loader from "../../Loader/Loader";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { CiEdit } from "react-icons/ci";
import HourlyRate from "../Settings/hourlyRate/HourlyRate";

const User = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [showPassword, setShowPassword] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [role, setRole] = useState("Time Reporter");
  const [roleId, setRoleId] = useState();
  const [showHourRate, setShowHourRate] = useState();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    domain: userInfo?.user?.domain,
  });
  const [passwordStrength, setPasswordStrength] = useState(0);
  const dispatch = useDispatch();

  const userLimits = {
    basic: 2,
    standard: 10,
    platinum: 50,
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "password") {
      calculatePasswordStrength(value);
    }
  };
  const calculatePasswordStrength = (password) => {
    let strength = 0;
    if (password.length >= 8) strength++;
    if (/[A-Z]/.test(password)) strength++;
    if (/[a-z]/.test(password)) strength++;
    if (/\d/.test(password)) strength++;
    if (/[@$!%*?&]/.test(password)) strength++;
    setPasswordStrength(strength);
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    const currentUserCount = users.length;
    const userPlanName = userInfo?.user?.plan?.name?.toLowerCase();
    console.log(userPlanName);

    const allowedUsers = userLimits[userPlanName];

    if (allowedUsers && currentUserCount >= allowedUsers) {
      toast?.dismiss();
      toast?.warn(
        `You can only add up to ${allowedUsers} users with your current plan. To add more, please upgrade your subscription.`
      );
      return;
    }

    if (
      !formData.first_name ||
      !formData.last_name ||
      !formData.email ||
      !formData.password
    ) {
      toast?.dismiss();
      toast?.error("All fields are required!");
      return;
    }

    setAddLoading(true);
    const userData = new FormData();
    userData.append("first_name", formData.first_name);
    userData.append("last_name", formData.last_name);
    userData.append("email", formData.email);
    userData.append("password", formData.password);
    userData.append("role", roleId);
    userData.append("domain", userInfo.user.domain);

    try {
      const result = await fetchApi.post("add-user/", userData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });

      if (result.status === 201) {
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        });
        toast?.dismiss();
        toast?.success("User added successfully!");
        setIsModalOpen(false);
        setAddLoading(false);
        getUsers();
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.email?.[0] ||
        error?.response?.data?.[0] ||
        "User add failed";
      toast?.dismiss();
      setAddLoading(false);
      toast?.error(errorMessage);
      console.log(error);
    } finally {
      setAddLoading(false);
    }
  };

  const handleGetRoles = async () => {
    try {
      const result = await fetchApi.get("roles/");
      if (result.status === 200) {
        const data = result.data;
        const userRole = data?.results?.filter((item) => item.name === role);
        setRoleId(userRole[0]?.id);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      toast?.dismiss();
      toast?.error("Something went wrong");
      console.log("Error getting roles: ", error);
    }
  };

  const getUsers = async () => {
    setLoading(true);

    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        setLoading(false);
        const filterUsers = response?.data?.results?.filter(
          (item) => item?.domain === userInfo?.user?.domain
        );
        setUsers(filterUsers);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast?.dismiss();
        toast?.error("Your session is expired");
        return;
      }
      console.log(error);

      setLoading(false);
      toast?.dismiss();
      toast?.error("Something went wrong");
    }
  };

  useEffect(() => {
    // console.log(userInfo)
    handleGetRoles();
    getUsers();
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }

  return (
    <>
      <ToastContainer />
      <style>
        {`
      .input {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
   border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  box-sizing: border-box;
  background-color: transparent;
  option{
     color: #000000; 
    }
}
      `}
      </style>
      {showHourRate && (
        <div className={styles.modalOverlay}>
          <div
            className={`${styles.modal} ${
              theme === "dark"
                ? "bg-[#212529] text-white"
                : "bg-[#e5e5e5] text-black"
            }`}
          >
            <HourlyRate setShowHourRate={setShowHourRate} />
          </div>{" "}
        </div>
      )}
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Users
      </div>
      <hr className="w-full" />
      <div
        className={`${styles.outlet}  ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        {isModalOpen && (
          <div className={styles.modalOverlay}>
            <div
              className={`${styles.modal} ${
                theme === "dark"
                  ? "bg-[#212529] text-white"
                  : "bg-[#e5e5e5] text-black"
              }`}
            >
              <div className={styles.modalHeader}>
                <h2 className="font-semibold ">Add new user</h2>
                <button
                  className={styles.closeButton}
                  onClick={() => setIsModalOpen(false)}
                >
                  &times;
                </button>
              </div>
              <form onSubmit={handleAddUser}>
                <div className={styles.formGroup}>
                  <label>First name</label>
                  <input
                    className="input"
                    type="text"
                    name="first_name"
                    value={formData.first_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Last name</label>
                  <input
                    className="input"
                    type="text"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>E-Mail</label>
                  <input
                    className="input"
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className={styles.formGroup}>
                  <label>Password</label>
                  <div className="relative">
                    <input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Enter your password"
                      className="input"
                      style={{ paddingRight: "35px" }}
                    />
                    <div
                      className="absolute right-3 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEye /> : <FaEyeSlash />}
                    </div>
                  </div>
                  <div className="w-full h-2 mt-2 rounded-full overflow-hidden">
                    <div
                      className={`h-full rounded-full transition-all duration-300 ${
                        passwordStrength < 2
                          ? "bg-red-500"
                          : passwordStrength < 4
                          ? "bg-orange-500"
                          : "bg-green-500"
                      }`}
                      style={{ width: `${(passwordStrength / 5) * 100}%` }}
                    ></div>
                  </div>
                </div>

                <div className={styles.formGroup}>
                  <label>User role</label>
                  <select
                    className="input"
                    name="role"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="Time Reporter">Time reporter</option>
                    <option value="Admin">Admin</option>
                  </select>
                </div>

                <div className={styles.actions} disabled={loading}>
                  <button type="submit" className="heroButton w-full">
                    {addLoading ? "Loading..." : "ADD"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}
        {loading ? (
          <Loader />
        ) : (
          <>
            <button
              className="mb-5 heroButton"
              onClick={() => setIsModalOpen(true)}
            >
              + New User
            </button>
            <div className="overflow-x-auto">
              <div className={styles.tableResponsive}>
                <table className={styles.table}>
                  <thead>
                    <tr>
                      <th>Empolyee</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Joining Date</th>
                      <th>Role</th>
                      <th>Hourly Rate</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((data, i) => (
                      <tr key={data?.id}>
                        <td className="py-1">
                          <img
                            src={profile}
                            alt="user icon"
                            className={styles.img}
                          />
                        </td>
                        <td>
                          {data?.first_name} {data?.last_name}
                        </td>
                        <td>{data?.email}</td>
                        <td>{formatDate(data?.created_at)}</td>
                        <td>
                          {typeof data?.role === "object"
                            ? data?.role?.name
                            : data?.role}
                        </td>
                        <td>
                          <span className="flex gap-2 items-center">
                            25${" "}
                            {userInfo?.user?.role?.name === "Admin" ? (
                              <CiEdit
                                onClick={() => {
                                  if (userInfo?.user?.role?.name === "Admin") {
                                    setShowHourRate(true);
                                  }
                                }}
                                className={`cursor-${
                                  userInfo?.user?.role?.name === "Admin"
                                    ? "pointer"
                                    : "not-allowed"
                                }`}
                              />
                            ) : (
                              ""
                            )}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default User;
