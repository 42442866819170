import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <hr className="footer-divider" />
        <div className="footer-content">
          <div>© 2024 BugTrack. All rights reserved.</div>
          <div className="footer-links">
            <Link to="/privacy_policy" className="footer-link">
              Privacy Policy
            </Link>
            <Link to="/terms_of_services" className="footer-link">
              Terms of Service
            </Link>
            <ul className="social-links">
              <li>
                <a href="https://www.linkedin.com/company/bugdev/" target="_blank" className="social-link">
                  <FaLinkedin className="footer-icon" />
                </a>
              </li>
              {/* <li>
                <a href="#twitter" className="social-link" target="_blank">
                  <FaTwitter className="footer-icon" />
                </a>
              </li> */}
              <li>
                <a href="https://www.instagram.com/bugdeve?igsh=YWlwd2ZvMXJkdWpj" target="_blank" className="social-link">
                  <FaInstagram className="footer-icon" />
                </a>
              </li>
              {/* <li>
                <a href="#facebook" className="social-link" target="_blank">
                  <FaFacebook className="footer-icon" />
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
