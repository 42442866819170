import React from "react";
import styles from "./error.module.css";
import NotFound from "../../assets/error.png";
import { NavLink } from "react-router-dom";

const Error = () => {
  return (
    <section className={styles.notfoundError}>
      <div className={styles.container}>
        <div className={styles.errorWrapper}>
          <div className={styles.contentWrapper}>
            <div className={styles.errorContent}>
              <h2>404 Error</h2>
              <h1>Page not found</h1>
              <p>The page you were looking for cannot be found.</p>
              <p>Please check the URL and try again.</p>
              <NavLink to="/" className="heroButton">
                Back to home
              </NavLink>
            </div>
            <div className={styles.errorImg}>
              <img src={NotFound} alt="error" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Error;
