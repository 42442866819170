import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";
import { toast } from "react-toastify";
import BackBtn from "../../backBtn/BackBtn";
import ParticlesBackground from "../../home/ParticalsBackground";
import AnimationLottie from "../../../lottie/animation-lottie";
import registerCompanyAnimation from "../../../lottie/registerCompanyAnimation.json";
import { toggleCompany } from "../../../Redux/Slices/registerCompany-slice";
import { fetchApi } from "../../../utlis/axios";




const RegCompany = () => {
  const { theme } = useSelector((state) => state.theme);
  const { userInfo } = useSelector((state) => state?.auth || {});
  

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState({
    name: "",
    site_url: "",
    logo: "",
    stamp: "",
    address: "",
    postal_code: "",
    city: "",
    state: "",
    country: "",
    owner: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (files) {
      setFormValues({ ...formValues, [name]: files[0] });
    } else {
      setFormValues({ ...formValues, [name]: value });
    }
  };

  const handleRegisterCompany = async (e) => {
    e.preventDefault();

    const requiredFields = ["name", "address", "postal_code", "city", "state", "country"];
    for (let field of requiredFields) {
      if (!formValues[field]) {
        toast.dismiss();
        toast.error(`${field.replace("_", " ")} is required!`);
        return;
      }
    }

    if (!isChecked) {
      toast.dismiss();
      toast.error("Please accept the Terms and Conditions to continue!");
      return;
    }

    setLoading(true);
    try {
      // Handle registration logic here
      const companyData = new FormData();
      companyData.append("name", formValues.name);
      companyData.append("site_url", formValues.site_url);
      // companyData.append("logo", formValues.logo);
      // companyData.append("stamp", formValues.stamp);
      companyData.append("address", formValues.address);
      companyData.append("postal_code", formValues.postal_code);
      companyData.append("city", formValues.city);
      companyData.append("state", formValues.state);
      companyData.append("country", formValues.country);
      companyData.append("owner", userInfo?.user?.id);
      const result = await fetchApi.post("companies/", companyData, {
                headers: {
                  Authorization: `Bearer ${userInfo.access_token}`,
                },
                
              })
              dispatch(toggleCompany({ companyInfo: formValues }));
      navigate("/dashboard");
      setLoading(false);

    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  const handleSkip = () => {
    navigate("/dashboard"); // You can modify this to navigate anywhere you like
  };

  return (
    <>
      <style>{`
        .login-box {
          background-color:${theme === "dark" ? "rgba(33, 33, 33, 0.9)" : "#dfdfdf"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .input-field {
          background-color:${theme === "dark" ? "#444" : "#ffffff"};
          color:${theme === "dark" ? "#ffffff" : "#000000"};
        }
        .forgot-link, .terms-text {
          color:${theme === "dark" ? "#ccc" : "#000000"};
        }
        .terms-link {
          color:${theme === "dark" ? "#ffffff" : "#ff2f2f"};
        }
      `}</style>
      <ParticlesBackground id="particaljs" />
      <div className="login-container">
        <BackBtn bgColor={"red"} />
        <div className="md:flex justify-center md:w-1/2 hidden">
          <AnimationLottie animationPath={registerCompanyAnimation} width={"70%"} />
        </div>
        <div className="flex justify-center w-full md:w-1/2">
          <div className="login-box">
            <h2 className="main-heading text-center">Register Your Company</h2>
            <form onSubmit={handleRegisterCompany}>
              <div className="input-group">
                <label className="input-label">Name*</label>
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  placeholder="Enter company name"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Site URL</label>
                <input
                  type="text"
                  name="site_url"
                  value={formValues.site_url}
                  onChange={handleChange}
                  placeholder="Enter site URL"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Logo</label>
                <input
                  type="text"
                  name="logo"
                  value={formValues.logo}
                  onChange={handleChange}
                  placeholder="logo"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Stamp</label>
                <input
                  type="text"
                  name="stamp"
                  value={formValues.stamp}
                  onChange={handleChange}
                  placeholder="stamp"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Address*</label>
                <input
                  type="text"
                  name="address"
                  value={formValues.address}
                  onChange={handleChange}
                  placeholder="Enter address"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Postal Code*</label>
                <input
                  type="text"
                  name="postal_code"
                  value={formValues.postal_code}
                  onChange={handleChange}
                  placeholder="Enter postal code"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">City*</label>
                <input
                  type="text"
                  name="city"
                  value={formValues.city}
                  onChange={handleChange}
                  placeholder="Enter city"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">State*</label>
                <input
                  type="text"
                  name="state"
                  value={formValues.state}
                  onChange={handleChange}
                  placeholder="Enter state"
                  className="input-field"
                />
              </div>
              <div className="input-group">
                <label className="input-label">Country*</label>
                <input
                  type="text"
                  name="country"
                  value={formValues.country}
                  onChange={handleChange}
                  placeholder="Enter country"
                  className="input-field"
                />
              </div>
              <div className="flex gap-2 items-center">
                <input
                  type="checkbox"
                  onClick={(e) => setIsChecked(e.target.checked)}
                  value={isChecked}
                />
                <p className="terms-text">
                  By clicking Register, you agree to our {" "}
                  <Link to="/terms_of_service" className="terms-link">
                    Terms of Service
                  </Link>{" "}
                  and {" "}
                  <Link to="/privacy_policy" className="terms-link">
                    Privacy Policy
                  </Link>
                  .
                </p>
              </div>
              <button
                className="heroButton w-full"
                type="submit"
                disabled={loading}
              >
                {loading ? "Loading..." : "Register"}
              </button>
            </form>
            <button
              className="heroButton w-full mt-4 bg-gray-400"
              onClick={handleSkip}
            >
              Skip
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegCompany;
