import React from 'react';
import Navbar from '../layout/Navbar';
import HeroSection from './heroSection/HeroSection';
import BoostProductivity from './boostProductivity/BoostProductivity';
import FeaturesSection from './featuresSection/FeacturesSection';
import PricingSection from './pricingSection/PricingSection';
import Footer from '../layout/Footer';
import HowItWorks from './howItWork/HowItWork';
import ContactUs from './contactUs/ContactUs';
import ParticlesBackground from './ParticalsBackground';
import GoToTop from '../goToTop/GoToTop';
import { useSelector } from 'react-redux';

const HomePage = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <div className={`home ${theme === 'dark' ? 'text-white' : 'text-black'}`}>
      <GoToTop/>
      <ParticlesBackground id="particaljs"/> 
      <Navbar />
      <HeroSection />
      <BoostProductivity />
      <FeaturesSection />
      <PricingSection />
      <HowItWorks />
      <ContactUs />
      <Footer />
    </div>
  );
};

export default HomePage;
