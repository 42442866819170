import React from "react";
import styles from "./previewModal.module.css";
import { IoMdCloseCircleOutline } from "react-icons/io";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";

const PreviewModal = ({ onClose, imgSrc }) => {

  return (
    <>
        <Swiper
          navigation={true}
          modules={[Navigation]}
          loop={true}
          className={styles.swiperContainer}
        >
          {imgSrc
            ?.filter((img) => img) 
            .map((img, index) => (
              <SwiperSlide key={index} style={{display:"flex",justifyContent:"center"}}>
                <img
                  src={img}
                  className={styles.modalImage}
                  alt={`Slide ${index + 1}`}
                />
              </SwiperSlide>
            ))}
        </Swiper>
        <button className={styles.closeButton} onClick={onClose}>
          <IoMdCloseCircleOutline />
        </button>
    </>
  );
};

export default PreviewModal;
