import React, { useState } from "react";
import styles from "./activity.module.css";
import { useSelector } from "react-redux";

const OfflineActivityForm = () => {
  const {theme}=useSelector((state)=>state.theme)
  const [time, setTime] = useState("16:01");
  const [duration, setDuration] = useState("00:15");
  const [description, setDescription] = useState("");
  const [project, setProject] = useState("");

  const handleAdd = () => {
    // Handle the add logic here
    console.log("Time:", time);
    console.log("Duration:", duration);
    console.log("Description:", description);
    console.log("Project:", project);
  }; 

  return (
    <div className={`${styles.offlineActivityForm} ${theme === 'dark' ? 'bg-darkMode' : 'bg-lightMode'}`}>
      <div className={styles.formRow}>
        <label>Time</label>
        <input
          type="time"
          value={time}
          onChange={(e) => setTime(e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.formRow}>
        <label>Duration</label>
        <input
          type="time"
          step="900"
          value={duration}
          onChange={(e) => setDuration(e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.formRow}>
        <label>Description</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={styles.input}
        />
      </div>
      <div className={styles.formRow}>
        <label>Project</label>
        <select
          value={project}
          onChange={(e) => setProject(e.target.value)}
          className={styles.input}
        >
          <option value="">Select Project</option>
          <option value="Project 1">Project 1</option>
          <option value="Project 2">Project 2</option>
        </select>
      </div>
      <button onClick={handleAdd} className='heroButton w-full'>
        Add
      </button>
    </div>
  );
};

export default OfflineActivityForm;
