import React from 'react';
import { IoTimerSharp } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { GoProjectRoadmap } from "react-icons/go";
import styles from './styles.module.css'; 
import { useSelector } from 'react-redux';

function FeaturesSection() { 
  const { theme } = useSelector((state) => state.theme);
  return (
    <section className={styles.featureMain} id='features'>
      <div className="container">
        <h2 className="main-heading">Optimize Workflows</h2>
        <div className={styles.featureContainer}>
          <div className={`${styles.featureContainerDiv} `}>
            <IoTimerSharp className={styles.featureIcon} />
            <h3 className="sub-heading">Time Logging</h3>
            <p className="textBase">Easily start and stop time tracking for tasks.</p>
          </div>
          <div className={`${styles.featureContainerDiv} `}>
            <FaTasks className={styles.featureIcon} />
            <h3 className="sub-heading">Task Categorization</h3>
            <p className="textBase">Organize tasks with custom categories.</p>
          </div>
          <div className={`${styles.featureContainerDiv} `}>
            <GoProjectRoadmap className={styles.featureIcon} />
            <h3 className="sub-heading">Project Tracking</h3>
            <p className="textBase">Monitor progress across multiple projects.</p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeaturesSection;
