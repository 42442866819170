import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    isLogin: false,
    userInfo: null,
  };

  const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
      toggleAuth: (state, { payload }) => {
        state.isLogin = payload?.isLogin;
        state.userInfo = payload?.userInfo;
      },
      setRememberMe: (state, action) => {
        state.rememberMe = action.payload;
      },
      setStoredCredentials: (state, action) => {
        state.userInfo = action.payload.userInfo;
      },
    },
  });
  export const { toggleAuth, setRememberMe, setStoredCredentials } = authSlice.actions;

  export default authSlice.reducer;
  