import React, { useState } from "react";
import { IoSearchSharp } from "react-icons/io5";
import styles from "./filterModal.module.css";
import { useSelector } from "react-redux";

const FilterModal = ({ onClose }) => {
  const { theme } = useSelector((state) => state.theme);
  const [title, setTitle] = useState("");
  const [timeZone, setTimeZone] = useState("My TZ (GMT0)");
  const [reportType, setReportType] = useState("User worktime");
  const [showDetailed, setShowDetailed] = useState(false);
  const [projects, setProjects] = useState(["Select All"]);
  const [users, setUsers] = useState(["Select All"]);
  const [period, setPeriod] = useState("Day");
  const [date, setDate] = useState("");
  const [format, setFormat] = useState("XLS");
  const [sendToEmail, setSendToEmail] = useState(true);
  const [email, setEmail] = useState("");
  const [sendIfZero, setSendIfZero] = useState(false);

  const handleCreate = () => {
    console.log({
      title,
      timeZone,
      reportType,
      showDetailed,
      projects,
      users,
      period,
      date,
      format,
      sendToEmail,
      email,
      sendIfZero,
    });
    onClose();
  };

  return (
    <>
      <style>
        {`
          .reportInput {
  width: 100%;
  padding: 10px;
  border: ${theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"};
  border-radius: 5px;
  margin-top: 5px;
  box-sizing: border-box; 
  overflow-y: auto;
  background-color: transparent;
}.reportInput option{
  color: black;
}

      `}
      </style>
      <div className={styles.modalOverlay}>
        <div
          className={`${styles.modalContent} ${
            theme === "dark"
              ? "bg-[#212529] text-white"
              : "bg-[#e5e5e5] text-black"
          }`}
        >
          <div className={styles.header}>
            <h2>Filter off</h2>
            <button className={styles.closeButton} onClick={onClose}>
              ✕
            </button>
          </div>
          <div className="text-left">
            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <label>Time zone</label>
                <select
                  className="reportInput"
                  value={timeZone}
                  onChange={(e) => setTimeZone(e.target.value)}
                >
                  <option>My TZ (GMT0)</option>
                  <option>Domain TZ (GMT0)</option>
                </select>
              </div>
              <div className={styles.formGroup}>
                <label>Month</label>
                <select
                  className="reportInput"
                  value={period}
                  onChange={(e) => setPeriod(e.target.value)}
                >
                  <option>August</option>
                  <option>July</option>
                  <option>June</option>
                </select>
              </div>
            </div>
            <div className={styles.formGroupRow}>
              <div className={styles.formGroup}>
                <label>Projects*</label>
                <div>
                  <div className={styles.formGroupSearch}>
                    <input
                      type="text"
                      placeholder="Search"
                      className="reportInput"
                    />
                    <IoSearchSharp className={styles.checkboxIcon} />
                  </div>
                  <select
                    className="reportInput"
                    multiple
                    value={projects}
                    onChange={(e) =>
                      setProjects(
                        [...e.target.selectedOptions].map((o) => o.value)
                      )
                    }
                  >
                    <option>Select All</option>
                    <option>Not selected</option>
                  </select>
                </div>
              </div>
              <div className={styles.formGroup}>
                <label>Users*</label>
                <div>
                  <div className={styles.formGroupSearch}>
                    <input
                      type="text"
                      placeholder="Search"
                      className="reportInput"
                    />
                    <IoSearchSharp className={styles.checkboxIcon} />
                  </div>
                  <select
                    className="reportInput"
                    multiple
                    value={users}
                    onChange={(e) =>
                      setUsers(
                        [...e.target.selectedOptions].map((o) => o.value)
                      )
                    }
                  >
                    <option>Select All</option>
                    <option>Hamza Tariq</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button className="heroButton" onClick={handleCreate}>
              Apply
            </button>
            <button className="heroButton" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilterModal;
