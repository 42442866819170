import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  signupInfo: {
  },
};

const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    toggleSignUp: (state, { payload }) => {
      state.signupInfo = payload?.signupInfo;
    },
  },
});

export const { toggleSignUp } = signupSlice.actions;

export default signupSlice.reducer;
