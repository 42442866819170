import React from "react";
import Lottie from "react-lottie";

const AnimationLottie = ({ animationPath, width = '95%' }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationPath,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return (
    <div style={{ width: width }}>
      <Lottie options={defaultOptions} />
    </div>
  );
};

export default AnimationLottie;
