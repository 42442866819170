import React, { useEffect, useState } from "react";
import BackBtn from "../backBtn/BackBtn";
import styles from "./styles.module.css";
import { togglePlan } from "../../Redux/Slices/plans-slice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ParticlesBackground from "../home/ParticalsBackground";
import GlowCard from "../home/pricingSection/glow-card";
import { fetchApi } from "../../utlis/axios";
import { toast } from "react-toastify";
import Loader from "../Loader/Loader";

const SubscriptionPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [plans, setPlans] = useState([]); 
  const [isLoading, setIsLoading] = useState(false);
  const { theme } = useSelector((state) => state.theme);

  const getPlans = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.get("plans/");
      if (result.status === 200) {
        setPlans(result.data);
      }
    } catch (error) {
      setIsLoading(false)
      toast.dismiss()
      toast.error("Something went wrong");
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPlans();
  }, []);

  const handlePlans = (plan) => {
    dispatch(togglePlan({ plan: plan }));
    navigate("/signup");
  };

  return (
    <>
      <ParticlesBackground id="particaljs" />
      <BackBtn bgColor={"red"} />
      <section
        className={`${styles.pricingSection} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        <div className="container">
          <h2 className={`main-heading text-center py-8 `}>
            Subscription Plans
          </h2>
          {isLoading ? (
            <Loader/>
          ) : (
            <div className={styles.pricingPlans}>
              {plans.map((plan, index) => (
                <GlowCard key={plan.id} identifier={index}> 
                  <div className={styles.plan}>
                    <h3 className={styles.planTitle}>{plan?.name}</h3> 
                    {/* <p className={styles.planDescription}>{plan?.description}</p>  */}
                    <p className={styles.planPrice}>
                    {plan?.description}
                    </p>
                    {/* <ul className={styles.planFeatures}>
                      <li>Feature 1</li>
                      <li>Feature 2</li>
                      <li>Feature 3</li>
                    </ul> */}
                    <button
                      className="heroButton"
                      onClick={() => handlePlans(plan?.id)} 
                    >
                      Sign Up
                    </button>
                  </div>
                </GlowCard>
              ))}
            </div>
          )}
        </div>
      </section>
    </>
  );
};

export default SubscriptionPage;
