import React, { useState } from 'react';
import { Stack, Alert } from '@mui/material';

const AlertBar = () => {
  const [showAlert, setShowAlert] = useState(true);

  const handleClose = () => {
    setShowAlert(false);
  };

  return (
    <Stack spacing={2}>
      {showAlert && (
        <Alert severity="warning" onClose={handleClose}>
          Please verify your email using the link sent to your email.
        </Alert>
      )}
    </Stack>
  );
};

export default AlertBar;
