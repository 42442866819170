import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import DashboardNavbar from "./Navbar/DashboardNavbar";
import GoToTop from "../goToTop/GoToTop";
import { useSelector } from "react-redux";
import AlertBar from "./AlertBar";
import SideBar from "./side_bar/Side_Bar";
import { MdOutlineChat } from "react-icons/md";
import { RxCross1 } from "react-icons/rx";

const Dashboard = () => {
  const { userInfo } = useSelector((state) => state?.auth || {});
  const { theme } = useSelector((state) => state.theme);
  const [isOpen, setIsOpen] = useState(false);

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className="relative min-h-screen overflow-hidden">
      {userInfo.user.is_verified === false ? <AlertBar /> : <></>}
      <GoToTop />
      <div className="flex min-h-screen text-white">
        {/* <div className="fixed z-50">
          <Sidebar isopen={isopen} toggle={toggle} />
        </div>
        <div
          className={`fixed top-0 left-0 w-full h-full bg-black bg-opacity-80 ${
            isopen ? "z-40" : "hidden"
          }`}
          onClick={toggleSidebar}
        /> */}
        <div
          className={`flex-grow min-h-screen  overflow-hidden ${
            theme === "dark" ? "bg-dashboardDarkMode" : "bg-white"
          }`}
        >
          <DashboardNavbar />
          <div className="flex justify-center overflow-x-hidden overflow-auto h-[calc(100vh-94px)] ">
            <div className="w-full flex flex-col items-center">
              <Outlet />
            </div>
            {/* <div
              className={`w-1/4 flex-grow p-5 lg:block hidden fixed right-4`}
            >
              <SideBar />
            </div> */}
          </div>
        </div>
      </div>
      <div
        className="bg-red-600 text-white h-12 w-12 absolute bottom-10 right-10 flex justify-center items-center cursor-pointer z-50 text-2xl ease-in-out transition-all"
        style={{ borderRadius: "50%" }}
        onClick={toggleChat}
      >
       {isOpen ? <RxCross1 /> : <MdOutlineChat />} 
      </div>
      {isOpen && (
        <div className="fixed bottom-24 right-8 w-80 max-w-full bg-white rounded-lg shadow-xl border-black border">
          <div className="flex items-center justify-between px-4 py-3 bg-red-600 text-white rounded-t-lg">
            <h3 className="font-semibold">Chat Support</h3>
           
          </div>

          <div className="p-4 max-h-72 overflow-y-auto">
            <p>Hello! How can we help you?</p>
          </div>

          <div className="flex items-center p-4 border-t border-gray-300">
            <input
              type="text"
              placeholder="Type a message..."
              className="flex-1 px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-red-600"
            />
            <button className="bg-red-600 text-white px-4 py-2 rounded-md ml-2 hover:bg-red-800 transition">
              Send
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
