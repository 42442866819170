import React from "react";
import styles from "./TermsOfService.module.css";
import { NavLink } from "react-router-dom";
import BackBtn from "../backBtn/BackBtn";
import { useSelector } from "react-redux";

const TermsOfService = () => {
  const { theme } = useSelector((state) => state.theme);
  return (
    <>
      <BackBtn bgColor={"red"}/>
      <div className={`${styles.container} ${theme === 'dark' ? 'bg-black text-white' : 'bg-white'}`}> 
        <h1 className={styles.title}>BugTrack Terms of Service</h1>
        <div className={styles.content}>
          <h2>User Roles and Responsibilities</h2>
          <p>
            BugTrack supports two types of users: <strong>Admin</strong> and{" "}
            <strong>Time Reporter</strong>. Admins can manage users, view
            detailed productivity reports, and access screenshots. Time
            Reporters are responsible for logging time, tracking productivity,
            and managing their own data.
          </p>

          <h2>Data Privacy</h2>
          <p>
            BugTrack collects user data, such as personal information, time
            logs, and activity screenshots, as part of the service. We
            prioritize the security of this data and adhere to strict privacy
            protocols. For more details on how your data is used, refer to our{" "}
            <NavLink
              to="/privacy_policy"
              className="underline hover:text-red-800"
            >
              Privacy Policy
            </NavLink>
            .
          </p>

          <h2>Use of Services</h2>
          <p>
            By using BugTrack, you agree to allow us to collect and store time
            logs, activity screenshots, and productivity data. This information
            is used to generate reports, improve our services, and provide
            insights to both users and admins. Any misuse of the service, such
            as unauthorized sharing of reports or tampering with data, may
            result in account termination.
          </p>

          <h2>Data Sharing</h2>
          <p>
            BugTrack does not share your personal data with third parties,
            except where it is essential to provide our services or where
            required by law. We use cookies to enhance user sessions and improve
            performance across the platform.
          </p>

          <h2>Legal Considerations</h2>
          <p>
            BugTrack may be required to disclose certain information in response
            to legal requests or obligations. We will take all reasonable steps
            to notify you of such requests unless prohibited by law.
          </p>

          <h2>Termination of Service</h2>
          <p>
            BugTrack reserves the right to suspend or terminate any account
            found in violation of these terms. Such actions may include misuse
            of data, sharing of unauthorized screenshots, or illegal activity
            within the platform.
          </p>
        </div>
      </div>
    </>
  );
};

export default TermsOfService;
