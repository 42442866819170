import React, { useEffect, useRef, useState } from "react";
import styles from "./report.module.css";
import { IoSearchSharp } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchApi } from "../../../utlis/axios";
import { toast } from "react-toastify";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { toggleAuth } from "../../../Redux/Slices/auth-slice";
import { MdOutlineFileDownload } from "react-icons/md";
import { BiEdit } from "react-icons/bi";
import { MdDeleteForever } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";

const Reports = () => {
  const { theme } = useSelector((state) => state.theme);
  const [title, setTitle] = useState("");
  const [period, setPeriod] = useState("monthly");
  const [reportData, setReportData] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const { userInfo } = useSelector((state) => state?.auth || {});
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersId, setUsersId] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const currentDate = new Date();
  const printRef = useRef();
  const dispatch = useDispatch();
  const [loadingSave, setLoadingSave] = useState(false);
  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  

  const openStartDateCalendar = () => {
    startDateRef.current.showPicker();
  };

  const openEndDateCalendar = () => {
    endDateRef.current.showPicker();
  };
  const formattedDate = currentDate.toLocaleDateString("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const getUsers = async () => {
    setIsLoading(true);

    try {
      const response = await fetchApi.get("users/", {
        headers: {
          Authorization: `Bearer ${userInfo.access_token}`,
        },
      });
      if (response.status === 200) {
        setIsLoading(false);

        if (userInfo?.user?.role?.name === "Time Reporter") {
          setUsersId(userInfo?.user?.id);
        } else {
          const result = response.data?.results.filter(
            (item) =>
              item.role.name === "Time Reporter" &&
              item.domain === userInfo.user.domain
          );
          if (result.length === 0) {
            setUsersId(userInfo?.user?.id);
          } else {
            setUsersId(result?.[0]?.id);
          }
          setUsers(result);
        }
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      setIsLoading(false);
      toast.dismiss();
      toast.error("Something went wrong");
    }
  };

  useEffect(() => {
    getUsers();
  }, []);

  const getData = async () => {
    setIsLoading(true);
    try {
      const result = await fetchApi.post(
        "report/",
        {
          users: usersId,
          domain: userInfo.user.domain,
          report_type: period,
          date_range: [startDate, endDate],
        },
        {
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        }
      );
      if (result.status === 200) {
        setIsLoading(false);
        setReportData(result.data);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        dispatch(toggleAuth({ isLogin: false, userInfo: null }));
        toast.dismiss();
        toast.error("Your session is expired");
        return;
      }
      setIsLoading(false);
      console.log(error);
    }
  };

  const handleCreate = async () => {
    if (!title || !startDate || !endDate || !usersId) {
      toast.error("Please fill all fileds");
      return;
    }
    await getData();
    setIsModalOpen(false)
    setShowPreview(true);
  };

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && selectedStartDate > endDate) {
      toast.dismiss();
      toast.error("Start date cannot be later than end date!");
    } else {
      setStartDate(selectedStartDate);
    }
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && selectedEndDate < startDate) {
      toast.dismiss();
      toast.error("End date cannot be earlier than start date!");
    } else {
      setEndDate(selectedEndDate);
    }
  };

  const handleSave = async () => {
    setLoadingSave(true);
    try {
      await new Promise((resolve) => setTimeout(resolve, 100));
      const canvas = await html2canvas(printRef.current, {
        scale: 2,
      });
      const imgData = canvas.toDataURL("image/png");

      const pdf = new jsPDF("p", "mm", "a4");
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("report.pdf");
      toast.dismiss();
      toast.success("Report saved as PDF!");
    } catch (error) {
      console.error("Error saving PDF:", error);
      toast.dismiss();
      toast.error("Failed to save PDF.");
    } finally {
      setLoadingSave(false);
    }
  };

  return (
    <>
      <style>
        {`
          .reportInput {
            width: 100%;
            padding: 10px;
            border: ${
              theme === "dark" ? "1px solid #ffffff" : "1px solid #000000"
            };
            border-radius: 5px;
            margin-top: 5px;
            box-sizing: border-box; 
            overflow-y: auto;
            background-color: transparent;
          }
          .reportInput option {
            color: black;
          }
        `}
      </style>
      <div
        className={`${styles.main_Heading} ${
          theme === "dark" ? "text-white" : "text-black"
        }`}
      >
        Reports
      </div>
      <hr className="w-full" />
      <div className={styles.main_container}>
        <div className={styles.actions}>
          {isModalOpen && (
            <div className={styles.modalOverlay}>
              <div
                className={`${styles.modal} ${
                  theme === "dark"
                    ? "bg-[#212529] text-white"
                    : "bg-[#e5e5e5] text-black"
                }`}
              >
                <div className={styles.modalHeader}>
                  <h2 className="font-semibold ">Create a Report</h2>
                  <button
                    className={styles.closeButton}
                    onClick={() => setIsModalOpen(false)}
                  >
                    &times;
                  </button>
                </div>
                <div
                  className={`${styles.modalContent} ${
                    theme === "dark" ? " text-white" : " text-black"
                  }`}
                >
                  <div className="text-left mt-3">
                    <div className="md:flex justify-between block ">
                      <div className="w-full md:w-[45%]">
                        <div className={styles.formGroup}>
                          <label>Title*</label>
                          <input
                            className={styles.reportInput}
                            type="text"
                            value={title}
                            placeholder="Enter report title"
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className={styles.formGroupRow}>
                          <div className={styles.formGroup}>
                            <label>Period</label>
                            <select
                              className={styles.reportInput}
                              value={period}
                              onChange={(e) => setPeriod(e.target.value)}
                            >
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div
                        className={`w-full md:w-[45%] ${styles.formGroupRow}`}
                      >
                        <div className={styles.formGroup}>
                          <div className={styles.formInnerGroup}>
                            <label>Users*</label>
                          </div>
                          <div>
                            <div className={styles.formGroupSearch}>
                              <input
                                type="text"
                                placeholder="Search"
                                className={styles.reportInput}
                              />
                              <IoSearchSharp className={styles.checkboxIcon} />
                            </div>
                            <select
                              className={styles.reportInputSelect}
                              multiple
                              value={usersId}
                              onChange={(e) => {
                                const selectedValues = [
                                  ...e.target.selectedOptions,
                                ].map((o) => o.value);
                                if (selectedValues.includes("all")) {
                                  setUsersId(users.map((data) => data?.id));
                                } else {
                                  console.log(selectedValues);
                                  
                                  setUsersId(selectedValues);
                                }
                              }}
                            >
                              {isLoading ? (
                                <option>Loading...</option>
                              ) : (
                                <>
                                  <option value="all">Select All</option>
                                  {userInfo?.user?.role?.name !==
                                  "Time Reporter" ? (
                                    <>
                                      {users.map((data) => {
                                        if (
                                          data?.role?.name === "Time Reporter"
                                        ) {
                                          return (
                                            <option
                                              key={data?.id}
                                              value={data?.id}
                                            >
                                              {data?.first_name}{" "}
                                              {data?.last_name}
                                            </option>
                                          );
                                        }
                                        return null;
                                      })}
                                    </>
                                  ) : (
                                    <option>
                                      {userInfo?.user?.first_name}{" "}
                                      {userInfo?.user?.last_name}
                                    </option>
                                  )}
                                </>
                              )}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex md:flex-row flex-col justify-between md:gap-0 gap-3 ${styles.formGroup}`}
                    >
                      <div
                        className="w-full md:w-[45%]"
                        onClick={openStartDateCalendar}
                      >
                        <label>Start Date</label>
                        <input
                          ref={startDateRef}
                          className={styles.reportInput}
                          type="date"
                          value={startDate}
                          onChange={handleStartDateChange}
                          placeholder="YYYY-MM-DD"
                        />
                      </div>
                      <div
                        className="w-full md:w-[45%]"
                        onClick={openEndDateCalendar}
                      >
                        <label>End Date</label>
                        <input
                          ref={endDateRef}
                          className={styles.reportInput}
                          type="date"
                          value={endDate}
                          onChange={handleEndDateChange}
                          placeholder="YYYY-MM-DD"
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.footer}>
                    <div className={styles.footer_innerDiv}>
                      <button
                        className="heroButton"
                        onClick={handleCreate}
                        disabled={isLoading}
                      >
                        {isLoading ? "Loading...." : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <button
            className="mb-5 heroButton"
            onClick={() => setIsModalOpen(true)}
          >
            Create Report
          </button>
          {showPreview && (
            <div className=" text-black w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg relative">
              <div ref={printRef} className="p-6">
                <h2 className="text-center text-xl">BugTrack Report</h2>
                <div className="mt-3">
                  <div>
                    <span className="font-semibold">Title:</span> {title}
                  </div>
                  <div>
                    <span className="font-semibold ">Name:</span>{" "}
                    {userInfo?.user?.first_name} {userInfo?.user?.last_name}
                  </div>
                  <div>
                    <span className="font-semibold">Email:</span>{" "}
                    {userInfo?.user?.email}
                  </div>
                  <div>
                    <span className="font-semibold">Date:</span> {formattedDate}
                  </div>
                  <div className="overflow-auto mt-4">
                    <table className="w-full text-center border">
                      <thead className="bg-gray-200">
                        <tr>
                          <th className="border p-2">Name</th>
                          <th className="border p-2">Date</th>
                          <th className="border p-2">Total Tracked Time</th>
                          <th className="border p-2">Keyboard Productivity</th>
                          <th className="border p-2">Mouse Productivity</th>
                          <th className="border p-2">Active Time</th>
                          <th className="border p-2">Active Time Percentage</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.entries(reportData || {}).map(
                          ([id, dateObjects]) => {
                            const user = users.find((item) => item.id === id);
                            return user
                              ? Object.entries(dateObjects).map(
                                  ([date, info]) => (
                                    <tr key={date}>
                                      <td className="border p-2 whitespace-nowrap">
                                        {user.first_name} {user.last_name}
                                      </td>
                                      <td className="border p-2 whitespace-nowrap">
                                        {date}
                                      </td>
                                      <td className="border p-2">
                                        {info.total_tracked_time}
                                      </td>
                                      <td className="border p-2">
                                        {info.keyboard_productivity_percentage}
                                      </td>
                                      <td className="border p-2">
                                        {info.mouse_productivity_percentage}
                                      </td>
                                      <td className="border p-2">
                                        {info.active_time}
                                      </td>
                                      <td className="border p-2">
                                        {info.active_time_percentage}
                                      </td>
                                    </tr>
                                  )
                                )
                              : null;
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className={`${styles.footer_innerDiv} pl-5 py-4`}>
                <button
                  className="heroButton"
                  onClick={() => setShowPreview(false)}
                >
                  Cancel
                </button>
                <button
                  className="heroButton"
                  onClick={handleSave}
                  disabled={loadingSave}
                >
                  {loadingSave ? "Saving..." : "Save Receipt"}
                </button>
              </div>
            </div>
          )}
          <>
            <div className="overflow-x-auto mt-5">
              <div className={styles.tableResponsive}>
                <table className={`${theme === "dark" ? 'text-white':'text-black'} ${styles.table}`}>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td
                        className="flex gap-2 items-center"
                        style={{ border: "none" }}
                      >
                        <IoIosArrowForward /> BugDev
                      </td>
                      <td style={{ border: "none" }}>Saved</td>
                      <td
                        className="flex gap-2 items-center"
                        style={{ border: "none" }}
                      >
                        <MdOutlineFileDownload /> <BiEdit /> <MdDeleteForever />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </>
        </div>
      </div>
    </>
  );
};

export default Reports;
